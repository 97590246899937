<div class="relative flex lg:flex-row flex-col justify-center gap-4 my-8 lg:my-16 px-8 xl:px-container lg:px-16 info lg:pe-0">  
    <div class="flex flex-col gap-4 lg:my-auto mb-8 px-8 py-8 w-full lg:w-1/2">      
      <h2 class="font-bold text-3xl text-white">Guarda tutto ciò che desideri, <br> quando lo vuoi tu</h2>
      <p class="text-xl">Scarica ora l'app di 7Live per goderti un'esperienza ancora più completa ed immersiva. Ora disponibile su Android e iOS.</p>
      <div class="flex md:flex-row flex-col gap-4 my-4">
        <a href="https://play.google.com/store/apps/details?id=com.sevenlive.app" target="_blank">
          <img class="block w-52 h-auto md:h-16" src="../../../assets/images/GetItOnGooglePlay_Badge_Web_color_Italian.png" alt="Scarica l'app Android">
        </a>
        <a href="https://apps.apple.com/us/app/7live-tv/id6667118264" target="_blank">
          <img class="block w-52 h-auto md:h-16" src="../../../assets/images/Download_on_the_App_Store_Badge_IT_RGB_blk_100317.svg" alt="Scarica l'app iOS">
        </a>
      </div>
    </div>
    <div class="relative bg-cover lg:bg-contain bg-no-repeat lg:bg-right w-full lg:w-1/2 h-1/2 lg:h-full sfondo"> </div>  
  </div>