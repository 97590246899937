import { Component, Input, OnInit } from '@angular/core';
import { ImmaginiNews, News } from '../../shared/interfacce.model';
import { ApiService } from '../../services/api.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notizia',
  templateUrl: './notizia.component.html',
  styleUrls: ['./notizia.component.css']
})
export class NotiziaComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router) { }

  @Input() notizie: News[] = []
  @Input() notizia!: News
  @Input() index!: number;

  immagini: ImmaginiNews[] = [];

  loading: boolean = false;

  ngOnInit(): void {

    this.loading = true;
    this.apiService.getImmaginiNotizia(this.notizia.id).subscribe({
      next: (data) => {
        this.immagini.push({ id: 0, immagine: this.notizia.copertina, id_news: this.notizia.id, data_creazione: new Date });
        data.map(immagine => this.immagini.push(immagine));
      },
      error: (err) => {
        this.loading = false;
        this.apiService.error("<p><strong>La immagini della news non sono state caricate correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
      },
      complete: () => {
        this.loading = false;
      }
    })

  }  

  onNotizia(id: number) {
    this.router.navigate(['/news/' + btoa("id_news=" + id)]);
  }
}
