<div class="contenuto xl:px-container lg:px-16 p-8 flex-col">
    <div class="condizioni text-justify">
        <h3 class="text-center font-bold uppercase text-2xl my-8">Condizioni Generali di Fornitura</h3>
        <p>Le presenti condizioni generali disciplinano le modalit&agrave; di attivazione e di fornitura dei servizi e
            contenuti a pagamento inclusi nei Channels allestiti in favore degli Utenti del servizio SEVEN LIVE WEB TV
            (&ldquo;Utenti&rdquo;).</p>
        <p>I CANALI di SEVEN LIVE WEB TV, previa registrazione dell&rsquo;Utente sul sito internet www.7live.biz
            (&ldquo;Sito&rdquo;), la creazione di un valido profilo personale (&ldquo;Account&rdquo;) e visionare
            qualsiasi
            prodotto on demand presente su sito stesso. I servizi ed i contenuti fruibili aderendo ai Canali on demand
            di
            Seven Live WEB TV sono selezionati e costantemente variati da Revenge srl in base a sue libere scelte
            editoriali,
            ai diritti di utilizzo dalla medesima acquisiti, ed alle caratteristiche tecnologiche delle piattaforme
            attraverso
            le quali l&rsquo;Utente pu&ograve; accedere ad essi.&nbsp;</p>
        <p>REVENGE SRL si riserva la facolt&agrave; di modificare, ampliare, sostituire, e/o eliminare, in qualunque
            momento, i contenuti inclusi nei CANALI.</p>
        <p>Il servizio SEVEN LIVE WEB TV ON DEMAND &egrave; erogato in conformit&agrave; a quanto previsto dal
            Regolamento
            UE del Parlamento Europeo e del Consiglio del 14 giugno 2017, n. 2017/ 1128, relativo alla
            portabilit&agrave;
            transfrontaliera di servizi di contenuti online nel mercato interno (&ldquo;Regolamento sulla
            Portabilit&agrave;
            Transfrontaliera&rdquo;).</p>
        <p>Per accedere ai servizi e ai contenuti occorre disporre di un&rsquo;adeguata connessione, fissa o mobile,
            alla
            rete internet e di un dispositivo idoneo, quale, a titolo meramente esemplificativo, personal computer,
            smart tv,
            smartphone, tablet, ecc., (&ldquo;Dispositivo Idoneo&rdquo;).</p>
        <p>Prima di aderire ad un&rsquo;Offerta di SEVEN LIVE WEB TV e di richiedere l&rsquo;attivazione dei CANALI on
            demand occorre sempre dotarsi:&nbsp;</p>
        <ul>
            <li>
                <p>di un accesso alla rete internet stipulando un separato accordo con gli operatori terzi che offrono i
                    servizi
                    di connettivit&agrave; internet (&ldquo;Operatori Terzi&rdquo;);</p>
            </li>
            <li>
                <p>di un Dispositivo Idoneo. Mediante il test di riproduzione audiovisiva &ldquo;test di banda&rdquo;
                    disponibile sul Sito ogni Utente pu&ograve; preventivamente verificare l&rsquo;accessibilit&agrave;
                    e la
                    qualit&agrave; di visione attraverso della propria connessione internet.</p>
            </li>
        </ul>        
        <p>L&rsquo;Utente prende atto del fatto che la qualit&agrave; di visualizzazione dei contenuti accessibili
            tramite i
            CANALI di SEVEN LIVE WEB TV dipendono: dalle caratteristiche e dalla corretta configurazione del dispositivo
            utilizzato per accedere al servizio SEVEN LIVE WEB TV e dalla qualit&agrave; e velocit&agrave; della
            connessione
            alla rete internet attivata dall&rsquo;Utente tramite gli Operatori Terzi.</p>
        <p>SEVEN LIVE WEB TV declina ogni responsabilit&agrave; in relazione a:</p>
        <ul>
            <li>
                <p>problemi di connettivit&agrave; alla rete internet;</p>
            </li>
            <li>
                <p>inidoneit&agrave;/malfunzionamento dei dispositivi utilizzati dagli Utenti;</p>
            </li>
            <li>
                <p>mancata/erronea configurazione dei dispositivi utilizzati dagli Utenti.</p>
            </li>
        </ul>        
        <h5>ATTIVAZIONE DEL CANALE ON DEMAND SEVEN LIVE WEB TV</h5>
        <p>&nbsp;Pu&ograve; essere attivato solo da Utenti&nbsp;maggiorenni&nbsp;in possesso di un Account SEVEN LIVE
            WEB
            TV. La procedura di attivazione pu&ograve; essere avviata accedendo alla pagina di registrazione del Sito o
            tramite l&rsquo;App Dedicata.</p>
        <p>Le credenziali (username e password) di accesso all&rsquo;Account SEVE LIVE WEB TV personale devono essere
            custodite con la massima diligenza, devono essere mantenute riservate e non possono essere comunicate a
            terzi od
            utilizzate da persone diverse dal loro titolare.</p>
        <p>L&rsquo;Utente che aderisce ad un&rsquo;Offerta a pagamento dovr&agrave; confermare, tramite i mezzi
            elettronici
            a sua disposizione, di avere letto, ben compreso, ed integralmente accettato le presenti Condizioni Generali
            di
            Fornitura (&ldquo;Condizioni Generali&rdquo;), la relativa informativa privacy, nonch&eacute; le specifiche
            condizioni dell&rsquo;Offerta prescelta.&nbsp;</p>
        <p>L&rsquo;Utente, all&rsquo;atto dell&rsquo;attivazione, si impegna a comunicare dati veritieri e completi, ed
            a
            provvedere al loro costante aggiornamento.</p>
        <p>REVENGE SRL si riserva la facolt&agrave; di verificare in qualsiasi momento la veridicit&agrave;, la
            completezza
            e l&rsquo;aggiornamento delle informazioni e dei dati comunicati dall&rsquo;Utente e, in caso di anomalie od
            omissioni, potr&agrave; interrompere e/o sospendere l&rsquo;erogazione dei servizi e dei contenuti e/o
            annullare
            la registrazione.</p>
        <p>Le Offerte di SEVEN LIVE WEB TV possono sempre prevedere limitazioni del numero massimo di Dispositivi Idonei
            medianti i quali &egrave; possibile fruire dei CANALI di SEVEN LIVE WEB TV.&nbsp;</p>
        <h5>DURATA DEL CONTRATTO</h5>
        <p>I contratti in modalit&agrave; &ldquo;Abbonamento&rdquo; hanno durata indeterminata, con decorrenza dal
            momento
            dell&rsquo;attivazione, da parte dell&rsquo;Utente, E L&rsquo;Utente ha facolt&agrave; di recedere dal
            contratto.&nbsp;</p>
        <h5>RECESSO DAI CONTRATTI IN MODALIT&Agrave; ABBONAMENTO&nbsp;</h5>
        <p>L&rsquo;Utente pu&ograve;, in qualunque momento, inoltrare una richiesta di recesso dal contratto con
            comunicazione a mezzo mail a&nbsp;<a href="mailto:recesso@7live.tv">recesso&#64;7live.tv</a>.Per effetto del
            recesso,
            l&rsquo;abbonamento perder&agrave; ogni efficacia e l&rsquo;Utente non potr&agrave; pi&ugrave; accedere ai
            CANALI
            &nbsp;on demand e ai servizi ed ai contenuti in esso inclusi. Tutti i pagamenti eseguiti fino al momento del
            recesso non sono rimborsabili.&nbsp;</p>
        <h5>CORRISPETTIVI CONTRATTUALI E FATTURAZIONE</h5>
        <p>I contratti in modalit&agrave; &ldquo;Abbonamento&rdquo; prevedono, di norma e fatte salve le diverse
            condizioni
            eventualmente previste in specifiche Offerte, l&rsquo;addebito di corrispettivi anticipati da pagarsi ogni
            30
            (trenta) giorni e anche di evoluzioni e/o arricchimenti dei servizi e dei CANALI, dandone preventiva
            comunicazione
            agli Utenti. L&rsquo;Utente, aderendo alle presenti Condizioni Generali, espressamente accetta che REVENGE
            SRL, in
            caso di mancato pagamento dei corrispettivi contrattuali, possa interrompere l&rsquo;erogazione dei servizi
            e dei
            contenuti on demand.&nbsp;</p>
        <h5>FRUIZIONE DEI SERVIZI SERVIZI SEVEN LIVE WEB TV</h5>
        <p>Il servizio SEVEN LIVE WEB TV e gli ulteriori servizi e contenuti inclusi nei CANALI di SEVEN LIVE WEB TV
            sono
            destinati all&rsquo;informazione, all&rsquo;istruzione ed all&rsquo;intrattenimento persona-le e/o
            famigliare
            dell&rsquo;Utente registrato e sono prestati nel pieno rispetto delle norme poste a tutela dei minori.</p>
        <p>&nbsp;Conseguentemente, l&rsquo;Utente si impegna a:&nbsp;</p>
        <ul>
            <li>
                <p>non utilizzare e/o diffondere, salvo espressa autorizzazione di REVENGE SRL, &nbsp;i CANALI, i
                    servizi e/o i
                    singoli contenuti pubblicati da REVENGE SRL per scopi di lucro od in ambiti professionali, quali
                    sono, ad
                    esempio, i locali pubblici od i locali aperti al pubblico e comunque in luoghi diversi
                    dall&rsquo;ambito
                    familiare e domestico, anche tramite altre tecnologie che non siano la rete internet;</p>
            </li>
            <li>
                <p>utilizzare il servizio SEVEN LIVE WEB TV e gli ulteriori servizi e contenuti inclusi nei CANALI di
                    SEVEN LIVE
                    WEB TV nel rispetto dei diritti di propriet&agrave; intellettuale e/o industriale di REVENGE SRL e/o
                    di terzi
                    che rendono disponibili i loro softwares o altri prodotti per l&rsquo;erogazione del Servizio;</p>
            </li>
            <li>
                <p>non copiare, diffondere, registrare, trasferire a terzi modificare e/o pubblicare in qualunque modo
                    e/o
                    mediante qualsiasi mezzo, anche parzialmente, i CANALI, i servizi, i contenuti, i segni distintivi,
                    i marchi,
                    i loghi, i testi, le immagini, i nomi e tutte le ulteriori informazioni accessibili tramite il
                    contratto;</p>
            </li>
            <li>
                <p>L&rsquo;Utente, aderendo alle presenti Condizioni Generali, espressamente riconosce di essere
                    l&rsquo;unico
                    responsabile dell&rsquo;eventuale fruizione del servizio SEVEN LIVE WEB TV e degli ulteriori servizi
                    e
                    contenuti inclusi nei CANALI di SEVEN LIVE WEB TV da parte di persone minorenni, e che
                    l&rsquo;eventuale
                    inosservanza degli obblighi sanciti dal presente articolo costituisce violazione grave degli impegni
                    contrattuali assunti e pu&ograve; integrare anche gli estremi di reato, con conseguente
                    facolt&agrave; di
                    REVENGE SRL di:&nbsp;</p>
                <ul>
                    <li>
                        <p>interrompere l&rsquo;erogazione dei servizi e dei contenuti;&nbsp;</p>
                    </li>
                    <li>
                        <p>comunicare la risoluzione di diritto del contratto ai sensi dell&rsquo;art. 1456 c.c.;&nbsp;
                        </p>
                    </li>
                    <li>
                        <p>fatto salvo ogni altro diritto di REVENGE SRL, ivi compreso quello di agire in giudizio per
                            ottenere il
                            risarcimento degli eventuali maggiori danni subiti. VARIAZIONI ED INTERRUZIONI DEI SERVIZI
                            SEVEN LIVE WEB
                            TV</p>
                    </li>
                </ul>
            </li>
        </ul>
        <p>Il servizio SEVEN LIVE WEB TV e gli ulteriori servizi e contenuti inclusi nei CANALI sono selezionati e
            costantemente variati da REVENGE SRL in base a sue libere scelte editoriali, ai diritti di utilizzo dalla
            medesima
            acquisiti ed alle caratteristiche tecnologiche delle piattaforme attraverso le quali l&rsquo;Utente
            pu&ograve;
            accedere ad essi.&nbsp;</p>
        <p>L&rsquo;Utente, in particolare, prende atto del fatto che REVENGE SRL, in considerazione della scadenza dei
            diritti di cui &egrave; titolare e dell&rsquo;eventuale acquisizione di nuovi diritti e/o nuovi contenuti,
            si
            riserva sempre la facolt&agrave; di variare, sostituire ed interrompere tutti i contenuti inclusi nelle
            Offerte e
            nei CANALI di SEVEN LIVE WEB TV, a qualunque genere essi appartengano.</p>
        <p>Pertanto, REVENGE SRL si riserva sempre la facolt&agrave; di:&nbsp;</p>
        <ul>
            <li>
                <p>modificare, ampliare, sostituire, rinominare e/o eliminare in qualunque momento i CANALI, le Offerte,
                    i
                    servizi e i contenuti fruibili dagli Utenti, nonch&eacute; di prevederne una fruizione differenziata
                    in base
                    ai diritti acquisiti, alla tipologia di Offerta attivata dagli Utenti, ed alle caratteristiche delle
                    piattaforme di accesso ai servizi e ai contenuti;</p>
            </li>
            <li>
                <p>interrompere l&rsquo;erogazione o limitare la fruizione dei CANALI, delle Offerte, dei contenuti e
                    dei
                    servizi, fruibili dagli Utenti per esigenze editoriali, tecniche e/o amministrative, ad esempio al
                    fine di
                    effettuare interventi di manutenzione, arricchimento e/o sviluppo dei servizi e dei contenuti, delle
                    applicazioni digitali e delle funzioni ad essi connesse.</p>
            </li>
        </ul>
        <p>REVENGE SRL rimane, dunque, esonerata da ogni tipo di responsabilit&agrave; e/o garanzia, espressa od
            implicita,
            relativa alla continuit&agrave;, frequenza, composizione, variet&agrave; ed entit&agrave; dei contenuti e
            dei
            servizi accessibili tramite il Servizio SEVEN LIVE WEB TV ed i suoi CANALI.</p>
        <h5>ASSISTENZA AGLI UTENTI</h5>
        <p>REVENGE SRL fornisce assistenza agli Utenti del Servizio tramite i canali di assistenza contattando la
            mail&nbsp;<a href="mailto:assistenza.webtv@7live.tv">assistenza.webtv&#64;7live.tv</a>.</p>
        <p>Gli Utenti possono avvalersi di tale mail per ricevere informazioni e chiarimenti nonch&eacute; per
            sottoporre a
            REVENGE SRL segnalazioni ed eventuali reclami.</p>
        <p>Qualsiasi reclamo pervenuto sar&agrave; analizzato affinch&eacute; possano essere valutate eventuali azioni
            di
            supporto all&rsquo;Utente oppure azioni migliorative dei servizi.</p>
        <p>Tutti i reclami inoltrati dagli Utenti e acquisiti attraverso i Canali di Assistenza saranno memorizzati
            tramite
            appositi sistemi informativi che ne garantiscono la tracciabilit&agrave;.</p>
        <h5>COMUNICAZIONI</h5>
        <p>L&rsquo;Utente, aderendo alle presenti Condizioni Generali, espressamente accetta di ricevere tutte le
            comunicazioni ed informazioni afferenti al contratto ed all&rsquo;Offerta prescelta (ad es. i documenti
            contrattuali, le fatture, gli avvisi e le richieste di REVENGE SRL, ecc.) in formato elettronico e tramite i
            recapiti dal medesimo comunicati a REVENGE SRL al momento della creazione dell&rsquo;Account e
            dell&rsquo;attivazione di Offerte a pagamento.</p>
        <h5>CESSIONE DEL CONTRATTO</h5>
        <p>L&rsquo;Utente, aderendo alle presenti condizioni generali di fornitura, espressamente:</p>
        <ul>
            <li>
                <p>riconosce di non poter cedere o trasferire a terzi il proprio contratto e le obbligazioni che da esso
                    discendono senza un&rsquo;autorizzazione scritta di REVENGE SRL;</p>
            </li>
            <li>
                <p>autorizza, in ogni caso, REVENGE SRL a cedere il contratto a terzi, senza necessit&agrave; di
                    successivi
                    avvisi o conferme, a condizione che la tutela dei suoi diritti contrattuali non venga in alcun modo
                    diminuita.
                </p>
            </li>
        </ul>
        <h5>LEGGE APPLICABILE E FORO COMPETENTE</h5>
        <p>I contratti SEVEN LIVE WEB TV &ndash; REVENGE SRL sono regolati dalla legge italiana. Per ogni eventuale
            controversia relativa ai servizi e ai contenuti attivati ed ai contratti saranno applicabili le norme
            vigenti in
            materia di Foro competente.</p>
        <h5>PRIVACY</h5>
        <p>I dati personali degli Utenti saranno trattati, anche con l&rsquo;ausilio di strumenti elettronici, nel pieno
            rispetto del Regolamento Europeo 2016/679 in materia di protezione dei dati personali (GDPR) e della
            normativa
            privacy nazionale, in adempimento a quanto previsto nei provvedimenti emessi dal Garante della Privacy
            nonch&eacute; degli specifici consensi espressi degli Utenti. L&rsquo;informativa completa sul trattamento
            dei
            dati personali &egrave; reperibile sul sito&nbsp;<a href="http://www.7live.tv">www.7live.tv</a>&nbsp;</p>

        <ul class="!ps-0 !list-none">
            <li>REVENGE SRL</li>
            <li>VIA PALESTRO 192</li>
            <li>96018 PACHINO (SR)</li>
            <li>COD. FISC. E ISCRIZIONE REG. IMPRESE 10940570012</li>
            <li>REA SR 450349</li>
            <li>TEL. 011 19620161</li>
            <li>TEL. 0931 1640011</li>
        </ul>
    </div>
</div>