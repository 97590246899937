import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { environment } from '../../../environments/environment.development';
import { Canale, CurrentVideo, Live } from '../../shared/interfacce.model';
import { ApiService } from '../../services/api.service';
import { MediaPlayerElement } from 'vidstack/elements';

@Component({
  selector: 'app-player-live',
  templateUrl: './player-live.component.html',
  styleUrls: ['./player-live.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class PlayerLiveComponent implements OnInit, AfterViewInit {

  constructor(private apiService: ApiService, private cdr: ChangeDetectorRef) { }

  @ViewChild('video') video?: ElementRef<MediaPlayerElement>;

  url: string = environment.apiUrl;

  lives: Live[] = [];
  canali: Canale[] = [];
  currentVideo?: CurrentVideo;

  carica: boolean = true;

  ngOnInit(): void {

    // Recupero le live del giorno
    this.apiService.getLives().subscribe({
      next: (data) => this.lives = [...data],
      error: (err) => {
        this.carica = false;
        this.apiService.error("<p><strong>I video live non sono stati caricati correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
      },
      complete: () => {
        
        if (this.lives.length > 0) {

            // Recupero i dati per far partire l'hls
            this.getDataVideo(this.lives[Math.floor(Math.random() * this.lives.length)])          
        }

      }
    })
  }

  ngAfterViewInit(): void {

    if (this.video) {

      this.video.nativeElement.logLevel = 'warn';
      this.video.nativeElement.loop = true;
      this.video.nativeElement.streamType = 'live';
      this.video.nativeElement.viewType = 'video';
      this.video.nativeElement.crossOrigin = true;
      this.video.nativeElement.playsInline = true;
      this.video.nativeElement.preload = 'auto';
      this.video.nativeElement.hideControlsOnMouseLeave = true;
      this.video.nativeElement.fullscreenOrientation = 'landscape';
      this.video.nativeElement.liveEdgeTolerance = 10;
      this.video.nativeElement.autoPlay = true;
    }

  }

  getDataVideo(live: Live) {

    // Seleziono come selezionata la live
    live.selected = true;

    // Recupero le informazioni e le imposto per il live in esposizione    
    this.currentVideo = { id: live.id, src: live.cloudflare_video_url, poster: live.cloudflare_image_url, durata: live.durata, titolo: live.titolo, id_canale: live.id_canale, canale: live.canale };

    if (this.video) {
      this.video.nativeElement.src = live.cloudflare_video_url;
      this.video.nativeElement.title = live.titolo;
      this.video.nativeElement.storage = '7live-' + live.titolo.replace(/ /g, '-')
    }

    this.carica = false;

    this.cdr.detectChanges();

  }

  onVideoChange(live: Live) {

    // Se il live non è stato selezionato procedo
    if (!live.selected) {

      // Ciclo per le live per cambiare il flag 'selected'
      this.lives.map(_live => _live.selected = live.id === _live.id)

      // Recupero i dati del video
      this.getDataVideo(live);
    }
  }

}
