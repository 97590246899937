import { Input, Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { Canale, Categoria, Ondemand, User } from '../../shared/interfacce.model';
import { environment } from '../../../environments/environment.development';


@Component({
  selector: 'app-on-demand',
  templateUrl: './on-demand.component.html',
  styleUrls: ['./on-demand.component.css']
})
export class OnDemandComponent implements OnInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router, private storageService: StorageService) { }

  @Input() abbonamentoScaduto: boolean = false;

  url: string = environment.apiUrl;

  loading: boolean = true;
  video: boolean = false;

  ondemands_filtrati: Ondemand[] = [];
  ondemands: Ondemand[] = [];

  ondemand?: Ondemand;

  canali: Canale[] = [];
  categorie: Categoria[] = [];

  num_ondemands: number = 15;

  titoloFilter = new FormControl();
  canaleFilter = new FormControl("-1");
  categoriaFilter = new FormControl("-1");

  user?: User;

  @HostListener("window:scroll", [])
  onScroll(): void {        
    if (document.body.scrollHeight - (window.innerHeight + window.scrollY) == 0) {      
      setTimeout(() => { this.onMostra(10); }, 1000);
    }
  }

  ngOnInit(): void {

    // Recupero l'utente loggato
    this.user = this.storageService.getUser();

    // Recupero i video ondemand
    this.apiService.getOndemands().subscribe({
      next: (data) => this.ondemands = [...data.filter(ondemand => ondemand.cloudflare_mp4_url != null)].sort(function (a, b) { return (a.abbonamento === b.abbonamento) ? 0 : a.abbonamento ? 1 : -1; }),
      error: (err) => {
        this.loading = false;
        this.apiService.error("<p><strong>I video Ondemand non sono stati caricati correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
      },
      complete: () => {
        this.ondemands_filtrati = this.ondemands;
        this.loading = false;
      }
    })

  }

  onOndemand(id: number) {
    this.router.navigate(['/ondemand/' + btoa("id_ondemand=" + id)]);
  }

  onMostra(num: number) {
    console.log(num)
    this.num_ondemands += num;
  }

  onClearFiltri() {
    this.canaleFilter.setValue('-1');
    this.categoriaFilter.setValue('-1')
    this.titoloFilter.setValue('');
  }

  onFiltro() {

    // Recupero i dati dentro ai filtri    
    let titolo = this.titoloFilter.value ? this.titoloFilter.value.toLowerCase() : '';

    // Filtro gli ondemand in base ai filtri inseriti
    this.ondemands_filtrati = this.ondemands.filter(video => video.titolo.toLowerCase().indexOf(titolo) >= 0)
    this.num_ondemands = 15;

  }
}
