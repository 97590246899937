<ng-container *ngIf="!loading">
  
    <!-- BANNER E TITOLO -->           
    <div class="banner" [ngStyle]="{'background-image': notizia?.copertina ? 'url('  + apiUrl + 'immagine/' + notizia?.copertina + ')': '' }"  *ngIf="notizia?.copertina" [@fadeInOut]></div>
    <!-- BANNER E TITOLO -->    

</ng-container>  

<div class="xl:px-container lg:px-16 px-8">

    <!-- LOGO MOBILE -->
    <div class="flex justify-center lg:py-4 py-8">
      <img class="lg:hidden relative z-10" src="../../../assets/images/Logo bianco.png" height="40px" alt="Logo 7live">
    </div>
    <!-- LOGO MOBILE -->        
  
    <!-- LOADER -->
    <app-video-loading class="flex items-center justify-center my-8" *ngIf="loading"></app-video-loading>  
    <!-- LOADER -->
    
    <div class="flex flex-col gap-4">
        <h3 class="font-bold text-3xl text-white lg:text-4xl testo">{{notizia?.titolo}}</h3>
        <p class="font-normal text-white  testo">{{notizia?.descrizione}}</p>  
        
        <div class="flex flex-wrap gap-4 mt-4">
            <div class="image" *ngFor="let immagine of immagini">                            
                <img [src]="apiUrl + 'immagine/' + immagine.immagine" alt="Immagine News" class="cursor-pointer object-contain h-64 hover:scale-[1.03] transition-all duration-300" (click)="openImg(popUpImage)" >
                <ng-template #popUpImage>
                    <div class="text-right">
                        <button (click)="popupImage?.close()" class="me-[5%] lg:me-0 text-4xl btn-primary btn-sm focus-visible:outline-none">&times;</button>
                        <img [src]="apiUrl + 'immagine/' + immagine.immagine" alt="" class="mx-auto max-w-[90%] lg:max-w-[100%] max-h-[90vh]">
                    </div>
                </ng-template>
            </div>
        </div>    
    </div>
  
</div>