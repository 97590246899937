<div class="content xl:px-container lg:px-16 lg:py-16 p-8">
  
    <div class="flex flex-row justify-center divide-x lg:mb-12 mb-8">
      <div class="text-center px-6 focus-visible:outline-none cursor-pointer" routerLink="/profilo" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="profilo-active" nzTooltipTitle="Gestisci i dati del tuo profilo!" nz-tooltip nzTooltipPlacement="bottom">
        <i class="nav-icon bi bi-person-fill"></i>
      </div>
      <div class="text-center px-6 focus-visible:outline-none cursor-pointer" routerLink="/profilo/abbonamento" routerLinkActive="profilo-active" nzTooltipTitle="Gestisci l'abbonamento a Seven Live Web TV!" nz-tooltip nzTooltipPlacement="bottom">
        <i class="nav-icon bi bi-credit-card-fill"></i>
      </div>
      <div class="text-center px-6 focus-visible:outline-none cursor-pointer" routerLink="/profilo/ticket" routerLinkActive="profilo-active" nzTooltipTitle="Crea e gestisci i tuoi ticket!" nz-tooltip nzTooltipPlacement="bottom">
        <i class="nav-icon bi bi-ticket-detailed-fill"></i>
      </div>
      <div class="text-center px-6 focus-visible:outline-none cursor-pointer" nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler effettuare il logout?" nzPopconfirmPlacement="bottom" (nzOnConfirm)="onLogout()" [nzOkText]="'Esci'" nzTooltipTitle="Logout dalla tua area personale!" nz-tooltip nzTooltipPlacement="bottom">
        <i class="nav-icon bi bi-box-arrow-right"></i>
      </div>
    </div>

    <div class="flex lg:flex-row flex-col gap-4">
      <!-- <div class="lg:w-1/3 w-full lg:block hidden">                  
        <button nz-button type="primary" nzSize="large" class="menu-button" routerLink="/profilo" [routerLinkActiveOptions]="{ exact: true }" routerLinkActive="active text-white">Gestisci il tuo profilo</button>
        <button nz-button type="primary" nzSize="large" class="menu-button" routerLink="/profilo/abbonamento" routerLinkActive="active text-white">Gestisci il tuo abbonamento</button>
        <button nz-button type="primary" nzSize="large" class="menu-button" routerLink="/profilo/ticket" routerLinkActive="active text-white">Apri un ticket</button>
        <button nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler effettuare il logout?" (nzOnConfirm)="onLogout()" nz-button nzType="primary" nzSize="large" class="bg-[#dc3545] menu-button-danger" [nzOkText]="'Esci'">Effettua il logout</button>
      </div>                 -->
      <div class="w-full">
        <router-outlet></router-outlet>
      </div>
    </div>  
</div>
