import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalAggiungiCanaleBackofficeComponent } from '../../canali-backoffice/modal-aggiungi-canale-backoffice/modal-aggiungi-canale-backoffice.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ApiService } from '../../../../services/api.service';
import { StorageService } from '../../../../services/storage.service';
import { Canale, Live, Ondemand, UserBackoffice } from '../../../../shared/interfacce.model';

@Component({
  selector: 'app-modifica-live-backoffice',
  templateUrl: './modifica-live-backoffice.component.html',
  styleUrls: ['./modifica-live-backoffice.component.css']
})
export class ModificaLiveBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private storageService: StorageService, private router: Router, private route: ActivatedRoute, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  formLive!: FormGroup;

  canali: Canale[] = [];
  ondemands: Ondemand[] = [];
  lives: Live[] = [];
  live?: Live;

  loading: boolean = false;
  alertVisible: boolean = false;
  alertModifica: boolean = false;
  visible: boolean = false;

  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';

  user!: UserBackoffice;

  ngOnInit(): void {

    // Recupero l'utente loggato nel backoffice
    this.user = this.storageService.getUserBackoffice();

    // Creo il form con i controlli
    this.formLive = new FormGroup({
      id_canale: new FormControl(null),
      id_ondemand: new FormControl(null),
      posizione: new FormControl(null)
    })

    // Recupero i parametri passati nell'url
    this.route.params.subscribe(params => {
      let id = params['id'];

      // Recupero i dati del live
      this.apiService.getLive(id).subscribe({
        next: (data) => this.live = data,
        error: (err) => this.router.navigate(['/backoffice/live']),
        complete: () => {

          // Recupero tutti i canali
          this.apiService.getCanali().subscribe({
            next: (data) => {

              this.canali = [...data];

              // Se l'utente è di tipo Amministratore
              if (this.user.tipo !== 'Amministratore') {

                // Se il live è di un canale diverso rispetto a quello collegato all'utente torno indietro
                if (this.live?.id_canale != this.user.id_canale) {
                  this.router.navigate(['/backoffice/live'])
                }

                // Se non sei amministratore mostro solo il tuo canale e blocco la scelta                
                this.formLive.get('id_canale')?.disable();
              }
            },
            error: (err) => this.router.navigate(['/backoffice/live']),
            complete: () => {

              this.apiService.getLives().subscribe({
                next: (data) => {
                  // Se l'utente è di tipo amministratore recupero tutti i canli
                  if (this.user?.tipo == 'Amministratore')
                    this.lives = [...data];
                  else {

                    // Filtro i canali solo per quelli disponibile all'utente
                    this.lives = [...data.filter((live: Live) => live.id_canale == this.user?.id_canale)];

                  }
                },
                error: (err) => this.onError("Le Live non sono state recuperate correttamente, ricaricare la pagina o aspettare qualche secondo!"),
                complete: () => {
                  // Compongo il form con i dati del live
                  this.formLive.setValue({
                    id_canale: this.live?.id_canale,
                    id_ondemand: this.live?.id_ondemand,
                    posizione: this.live?.posizione                    
                  })
                }
              })


            }
          })
        }
      })

    });
  }

  onClose() {
    this.router.navigate(['/backoffice/live']);
  }

  onCanale(canale: string) {

    // Se è stato selezionato 'Altro' nei canli procedo
    if (canale == '0') {

      // Creo il modal per aggiungere il canale
      let modal = this.modal.create({
        nzTitle: 'Aggiungi Canale',
        nzContent: ModalAggiungiCanaleBackofficeComponent,
        nzViewContainerRef: this.viewContainerRef,
        nzFooter: null,
        nzCentered: true,
        nzWidth: 1250,
        nzBodyStyle: { 'padding': '0px' }
      });

      // Quando viene chiuso il modal procedo
      modal.afterClose.subscribe(() => {

        // Recupero i canali
        this.apiService.getCanali().subscribe({
          next: (data) => this.canali = [...data],
          error: (err) => this.onError("Canali non caricati correttamente, ricaricare la pagina o aspettare qualche secondo!"),
          complete: () => this.formLive.patchValue({ id_canale: null })
        })

      })
    } else {

      // Recupero i video ondemand del canale
      this.apiService.getOndemandsCanale(+canale).subscribe({
        next: (data) => this.ondemands = [...data],
        error: (err) => this.onError("Ondemand non caricati correttamente, ricaricare la pagina o aspettare qualche secondo!"),
        complete: () => { }
      })

    }
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  toHHMMSS(value: any) {

    // Converto la durata dei video nel formato ORA MINUTI SECONDI
    var sec_num = parseInt(value, 10); // don't forget the second param
    var hours: any = Math.floor(sec_num / 3600);
    var minutes: any = Math.floor((sec_num - (hours * 3600)) / 60);
    var seconds: any = sec_num - (hours * 3600) - (minutes * 60);

    if (hours < 10) { hours = "0" + hours; }
    if (minutes < 10) { minutes = "0" + minutes; }
    if (seconds < 10) { seconds = "0" + seconds; }
    return hours + ':' + minutes + ':' + seconds;
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta api
    let live = {
      id_canale: this.formLive.get('id_canale')?.value,
      id_ondemand: this.formLive.get('id_ondemand')?.value,
      posizione: this.formLive.get('posizione')?.value.toString(),
    }

    this.loading = true;

    // Controllo che tutti i dati richiesti siano stati inseriti, se non sono stati inseriti non procedo
    if (!live.id_canale) {
      this.onError("Inserire un titolo per procedere!");
    } else if (!live.id_ondemand) {
      this.onError("Inserire un canale per procedere!");
    } else {

      this.alertVisible = false;

      // Aggiorno il live sul db
      this.apiService.updateLive(this.live!.id, live).subscribe({
        next: (data) => this.apiService.setLive(),
        error: (err) => this.onError(err.error.message),
        complete: () => {
          this.loading = false;
          this.router.navigate(['/backoffice/live']);
        }
      })

    };
  }
}