import { Component, TemplateRef } from '@angular/core';
import { ImmaginiNews, News } from '../../../shared/interfacce.model';
import { ApiService } from '../../../services/api.service';
import { environment } from '../../../../environments/environment.development';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { Dialog, DialogRef } from '@angular/cdk/dialog';

@Component({
  selector: 'app-singola-news',
  templateUrl: './singola-news.component.html',
  styleUrl: './singola-news.component.css',
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class SingolaNewsComponent {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router,  private dialog: Dialog) { }

  notizia?: News
  immagini: ImmaginiNews[] = [];

  copertina_url: string = '';
  apiUrl: string = environment.apiUrl;

  loading: boolean = false;

  popupImage: DialogRef<any, unknown> | undefined;

  ngOnInit(): void {

    // Recupero i parametri nell'url
    this.route.params.subscribe(params => {
      const string = params['id'];      
      const id = atob(string).split('id_news=')[1];                

      this.loading = true;

      // Recupero la notizia
      this.apiService.getNotizia(+id).subscribe({
        next: (data) => this.notizia = data,
        error: (err) => this.router.navigate(['/news']),
        complete: () => {       
          
          this.copertina_url = 'url("'  + this.apiUrl + 'immagine/' + this.notizia?.copertina + '")';          

          this.apiService.getImmaginiNotizia(this.notizia!.id).subscribe({
            next: (data) => this.immagini = [...data],
            error: (err) => {
              this.loading = false;
              this.apiService.error("<p><strong>La immagini della news non sono state caricate correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
            },
            complete: () => {
              console.log(this.copertina_url)
              this.loading = false;
            }
          })
        }
      })

    });

  }

  openImg(template: TemplateRef<unknown>) {
    this.popupImage = this.dialog.open(template);
  }

}
