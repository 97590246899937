<!-- <img class="bg" #img alt="" (error)="img.src = '../../../assets/images/default_copertina.png'">
<div class="overlay" (mouseenter)="onMouse(true)" (mouseleave)="onMouse(false)">
    <span class="durata">{{getTime(ondemand.durata)}}</span>
    <div class="free-badge" *ngIf="!ondemand.abbonamento">
        <span>Video gratuito!</span>
        <i class="bi bi-unlock-fill"></i>
    </div>
    <div class="testi inline-block">
        <div class="vrappatore">
            <div class="descrizione">
                <h5 id="titolo" class="p-0 m-0 text-[16px] font-semibold text-white testo crop" [attr.title]="ondemand.titolo">{{ondemand.titolo}}</h5>
                <p id="desc" class="p-0 m-0 text-white testo crop">{{ondemand.descrizione}}</p>
            </div>
        </div>
    </div>
</div> -->

<div class="wrapper relative w-full cursor-pointer">
    <div class="card wrapper__front transition-all ease-out delay-200 duration-200 relative">
        <div class="free-badge" *ngIf="!ondemand.abbonamento">            
            <i class="bi bi-unlock-fill"></i>
        </div>
        <img #img class="card__image" [src]="ondemand.copertina ? (ondemand.copertina | image) : ondemand.cloudflare_image_url" (error)="img.src = '../../../assets/images/default_copertina.png'" alt="snowpiercer series banner" />      
    </div>
    <div class="card wrapper__back relative">      
        <img class="card__image" [src]="ondemand.cloudflare_preview_url" alt="snowpiercer series banner" />      
        <div class="px-4 py-6 flex flex-row gap-2 items-center relative z-10 bg-header-color">        
            <div class="flex flex-col gap-2 w-3/4">
                <p class="text-white font-bold text">{{ondemand.titolo}}<span class="font-normal"><br>{{getTime(ondemand.durata)}} min</span></p>                
            </div>
            <div class="w-1/4 flex justify-center">
                <div class="play rounded-full border-white border hover:bg-gray-400/50 aspect-square cursor-pointer">
                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" aria-hidden="true" xmlns="http://www.w3.org/2000/svg">
                        <path d="M10.6667 6.6548C10.6667 6.10764 11.2894 5.79346 11.7295 6.11862L24.377 15.4634C24.7377 15.7298 24.7377 16.2692 24.3771 16.5357L11.7295 25.8813C11.2895 26.2065 10.6667 25.8923 10.6667 25.3451L10.6667 6.6548Z" fill="currentColor" />
                    </svg>
                </div>
            </div>
        </div>
    </div>
</div>