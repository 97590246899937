<div class="xl:px-container lg:px-16 px-8">

  <!-- LOGO MOBILE -->
  <div class="flex justify-center lg:py-4 py-8">
    <img class="lg:hidden relative z-10" src="../../../assets/images/Logo bianco.png" height="40px" alt="Logo 7live">
  </div>
  <!-- LOGO MOBILE -->
    
  <h3 class="text-white font-bold lg:text-5xl text-3xl my-8">News</h3>  

  <!-- LOADER -->
  <app-video-loading class="flex items-center justify-center my-8" *ngIf="loading"></app-video-loading>  
  <!-- LOADER -->
  
  <ng-container *ngIf="!loading">

    <!-- NO NEWS -->
    <h3 class="mt-4 flex justify-center items-center text-white font-semibold lg:text-2xl text-xl" *ngIf="notizie.length <= 0">Nessuna news caricata</h3>  
    <!-- NO NEWS -->

    <!-- NEWS -->
    <app-notizia *ngFor="let notizia of notizie; let i = index" [notizia]="notizia" [notizie]="notizie" [index]="i"></app-notizia>
    <!-- NEWS -->

  </ng-container>  

</div>