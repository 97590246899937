<ng-template #newsTitle>
    <h3 class="text-white mb-0 text-xl font-semibold">Aggiungi Immagini News</h3>
</ng-template>    
<nz-card [nzTitle]="newsTitle" [nzActions]="[actionClose, actionAdd]">
    <form (ngSubmit)="onSubmit()" id="formNews" class="flex flex-col gap-4">
        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>          
        <div class="w-full text-center my-6">                
            <nz-upload [(nzFileList)]="imageList" nzMultiple [nzBeforeUpload]="beforeImageUpload" nzAccept="image/*" [nzRemove]="removeImageUpload" class="w-full">
                <button nzSize="large" nz-button type="button" class="home-button">
                  <span class="align-middle" nz-icon nzType="upload"></span>
                  Carica immagini news
                </button>
            </nz-upload>                                        
        </div>          
        <ng-container *ngIf="immagini.length > 0">
            <nz-divider class="!mt-0"></nz-divider>
            <div class="w-full mb-6">
                <h3 class="mb-4">Immaigni caricate</h3>
                <div class="flex flex-wrap gap-4">
                    <div class="image relative" *ngFor="let immagine of immagini">
                        <img [src]="apiUrl + 'immagine/' + immagine.immagine" alt="Immagine News" class="cursor-pointer object-contain h-64 hover:scale-[1.03] transition-all duration-300">                    
                        <i class="bi text-xl bi-x-lg rounded-full bg-accent aspect-square absolute -right-3 -top-3 flex items-center justify-center p-2 w-9 cursor-pointer" (click)="onEliminaImmagine(immagine.immagine, immagine.id_news, immagine.id)"></i>
                    </div>

                </div>
            </div>
        </ng-container>
    </form>
</nz-card>    
<ng-template #actionClose>
    <button nz-button nzSize="large" type="button" (click)="onClose()">Annulla</button>        
</ng-template>
<ng-template #actionAdd>
    <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formNews">Aggiungi</button>        
</ng-template>