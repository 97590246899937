<footer class="lg:py-12 py-16">
    <div class="xl:px-container lg:px-16 px-8 mx-auto flex lg:flex-row flex-col gap-4 items-center justify-center">        
        <div class="lg:w-1/3 w-full">
            <div class="logo-footer flex lg:justify-start justify-center"><img src="../../../assets/images/Logo bianco.png" alt="Logo Seven Live Web TV"></div>
        </div>
        <div class="lg:w-1/3 w-full">
            <div class="socials lg:my-0 my-4 flex justify-center items-center gap-8">
                <div class="logo-social">
                    <a href="https://www.facebook.com/pages/Seven-Live-TV/108112955491?fref=ts" class="hover:text-accent" target="_blank" title="Facebook Seven Live Web TV">
                        <i class="bi bi-facebook text-3xl hover:text-accent"></i>
                    </a>
                </div>
                <div class="logo-social">
                    <a href="https://www.youtube.com/@sergiogiovanniceruttiSevenLive" class="hover:text-accent" target="_blank" title="Youtube Seven Live Web TV">
                        <i class="bi bi-youtube text-3xl hover:text-accent"></i>
                    </a>
                </div>
                <div class="logo-social">
                    <a href="https://www.instagram.com/sevenlive_webtv/" class="hover:text-accent" target="_blank" title="Instagram Seven Live Web TV">
                        <i class="bi bi-instagram text-3xl hover:text-accent"></i>
                    </a>
                </div>
            </div>
        </div>
        <div class="lg:w-1/3 w-full contacts lg:text-right text-center">
            <p routerLink="/contattaci">CONTATTACI</p>
            <p routerLink="/condizioni">CONDIZIONI GENERALI di FORNITURA</p>
            <p routerLink="/privacy">INFORMATIVA e PRIVACY</p>
        </div>
    </div>    
</footer>
<div class="footer-placeholder"></div>