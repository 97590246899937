import { Component, Input } from '@angular/core';
import { Canale } from '../../shared/interfacce.model';

@Component({
  selector: 'app-canale-thumbnail',
  templateUrl: './canale-thumbnail.component.html',
  styleUrls: ['./canale-thumbnail.component.css']
})
export class CanaleThumbnailComponent {

  constructor() { }

  @Input() canale?: Canale;

}