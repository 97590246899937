import { BreakpointObserver } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '../../shared/interfacce.model';
import { StorageService } from '../../services/storage.service';

import 'vidstack/player';
import 'vidstack/player/layouts/default';
import 'vidstack/player/ui';
import { filter, map } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor(private observer: BreakpointObserver, private storageService: StorageService, private router: Router, private cdr: ChangeDetectorRef) { }

  mobile: boolean = false;
  loading: boolean = true;

  route: string = '';

  user?: User;

  ngOnInit(): void {    

    // Faccio aspettare mezzo secondo e rimuovo il caricamento
    setTimeout(() => { this.loading = false; }, 500);

    this.user = this.storageService.getUser();
    this.storageService.UserChanged.subscribe({
      next: (data) => this.user = this.storageService.getUser()
    })

    this.route = this.router.url;        
    // Ogni volta che viene cambiato la route
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd), map((event: any) => {      
      this.route = event.url
      return event;
    })).subscribe(() => {

      // Setto il caricamento a true
      this.loading = true;

      // Faccio aspettare mezzo secondo e rimuovo il caricamento
      setTimeout(() => { this.loading = false; }, 500);

    });

  }

  ngAfterViewInit() {
    this.observer.observe(['(max-width: 990px)']).subscribe((res) => {
      this.mobile = res.matches ? true : false;
      this.cdr.detectChanges();
    });
  }

  logout() {
    this.storageService.clearUser();
    this.router.navigate(['/home']);
  }
}