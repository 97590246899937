<ng-template #categoriaTitle>
    <h3 class="text-white mb-0 font-semibold text-xl">Aggiungi Categoria</h3>
</ng-template>    
<nz-card [nzTitle]="categoriaTitle" [nzActions]="[actionClose, actionAdd]">    
    <form [formGroup]="formCategoria" (ngSubmit)="onSubmit()" id="formCategoria" class="flex flex-col gap-4">

        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>
                                                                       
        <nz-form-item>
            <nz-form-label nzRequired>Titolo</nz-form-label>
            <nz-form-control>
                <input nz-input nzSize="large" formControlName="titolo" required />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label>Descrizione</nz-form-label>
            <nz-form-control>
                <textarea nz-input nzSize="large" formControlName="descrizione"></textarea>
            </nz-form-control>
        </nz-form-item>        
    </form>
</nz-card>
<ng-template #actionClose>
    <button nz-button nzSize="large" type="button" (click)="onAnnulla()">Annulla</button>        
</ng-template>
<ng-template #actionAdd>
    <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formCategoria">Aggiungi</button>
</ng-template>