<nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>

<ng-container *ngIf="!abbonamento">
  <h4 class="font-semibold text-xl mb-4">Gestisci il tuo abbonamento</h4>
  <p>Entra a far parte di Seven Live Web TV, per goderti i nostri fantastici video On Demand. Attiva ora il tuo abbonamento!</p>
  <div class="mt-8 w-full text-end">
    <button class="bottone" nzType="primary" nz-button [nzLoading]="loading" (click)="onAbbonamento()">ATTIVA L'ABBONAMENTO</button>
  </div>
</ng-container>

<ng-container *ngIf="abbonamento">  
  <h4 class="font-semibold text-xl mb-4">Gestisci il tuo abbonamento</h4>
  <p>Stato abbonamento: <span class="status w-fit inline-flex items-center rounded-md px-2 py-1 font-medium ring-1 ring-inset ring-gray-500/10" [ngClass]="{'bg-[#28a745]': abbonamento.stato == 'attivo', 'bg-[#ffc107]': abbonamento.stato == 'sospeso'}">{{abbonamento.stato | titlecase }}</span></p>
  <p> {{abbonamento.stato === 'attivo' ? "L'abbonamento verrà rinnovato automaticamente il" :  "L'abbonamento verrà automaticamente annullato il" }} <strong>{{abbonamento.stripe_scadenza | date: 'dd/MM/yyyy'}}</strong> alle <strong> {{abbonamento.stripe_scadenza | date: 'HH:mm:ss'}}</strong> al prezzo di <span class="fw-semibold">{{abbonamento.prezzo | currency: 'EUR'}}</span></p>
  <div class="mt-8 w-full text-end">
    <button class="bottone" nzType="primary" nz-button nzSize="large" [nzLoading]="loading" (click)="abbonamento.stato === 'attivo' ? onSospendi() : onAttivo()">{{abbonamento.stato === 'attivo' ? "SOSPENDI ABBONAMENTO" : "ATTIVA ABBONAMENTO"}}</button>
  </div>  
</ng-container>

<div class="rounded-2 border border-dashed my-8"></div>  

<div class="tabella">

  <h4 class="font-semibold text-xl mb-4">Storico pagamenti</h4>
  <p *ngIf="storico.length <= 0" class="text-center my-4 text-muted">Non hai associato nessun pagamento...</p>

  <nz-table #storicoTable *ngIf="storico.length > 0" [nzData]="storico" nzShowPagination [nzTotal]="(storico).length" [nzPageSize]="5" [nzPageSizeOptions]="[5, 10, 20, 30, 50, 100]" nzShowSizeChanger class="p-0">
    <thead>
      <tr>
        <th>Stato</th>
        <th>Importo</th>
        <th>Data</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let data of storicoTable.data">
        <td>{{ data.stato == 'paid' ? 'Pagato' : 'Annullato' }}</td>
        <td>{{ data.importo | currency: 'EUR' }}</td>
        <td>{{ data.data_creazione }}</td>
      </tr>
    </tbody>
  </nz-table>
</div>