<div class="xl:px-container lg:px-16 px-8">

  <!-- LOGO MOBILE -->
  <div class="flex justify-center lg:py-4 py-8">
    <img class="lg:hidden" src="../../../assets/images/Logo bianco.png" height="40px" alt="Logo 7live">
  </div>
  <!-- LOGO MOBILE -->

  <!-- TITOLO PAGINA -->
  
  <h3 class="text-white font-bold lg:text-4xl text-3xl mt-8 mb-4">On Demand</h3>
  <p class="text-white text-xl mb-8">Rivivi i nostri momenti e scopri nuovi video</p>  
  <!-- TITOLO ON DEMAND -->

  <!-- BANNER -->

  <!-- NO ABBONAMENTO || NO LOGIN -->  
  <div class="avviso py-12 lg:px-0 px-8 bg-dark rounded flex justify-center flex-col gap-4" *ngIf="abbonamentoScaduto">
    <h3 class="text-white text-center lg:text-2xl text-xl">{{user?.id ? 'Sembra che tu non abbia nessun abbonamento attivo...' : 'Per visualizzare questo video On Demand accedi o registrati prima.'}}</h3>
    <p class="text-white text-center">{{user?.id ? 'Abbonati ora per poter visionare il video On Demand!' : "Dopo aver effettuato la registrazione o l'accesso, se non sei ancora abbonato a Seven Live Web TV, effettualo per poter visionare il video On Demand."}}</p>
    <button class="bottone" nzType="primary" nz-button [routerLink]="user?.id ? '/profilo/abbonamento' : '/login'">{{user?.id ? 'Abbonati ora': 'Login'}}</button>
  </div>  
  <!-- NO ABBONAMENTO || NO LOGIN -->

  <!-- BANNER SELEZIONE VIDEO -->  
  <div class="avviso py-12 px-3 bg-dark rounded flex justify-center flex-col" *ngIf="!video && !abbonamentoScaduto">
    <h3 class="text-white text-center lg:text-2xl text-xl">Premi o clicca su un video On Demand per poterlo vedere</h3>
  </div>  
  <!-- BANNER SELEZIONE VIDEO -->
  
  <!-- BANNER -->

  <!-- FILTRI -->
  <div class="flex lg:flex-row flex-col gap-2 mt-8">
    <div class="w-full">
      <nz-input-group [nzSuffix]="suffixIconSearch" class="h-full ricerca pl-4" nzSize="large">
        <input id="queryFiltroVideo" nz-input type="text" [formControl]="titoloFilter" (ngModelChange)="onFiltro()" placeholder="Cerca...">
      </nz-input-group>
      <ng-template #suffixIconSearch>
        <span nz-icon nzType="search"></span>
      </ng-template>
    </div>
    <!-- <div class="lg:w-3/12 w-full">      
      <select name="canale" class="h-full ricerca ricerca-select focus-visible:outline-none px-4 py-1.5" (ngModelChange)="onFiltro()" [formControl]="canaleFilter">
        <option class="px-4" value="-1" selected>Tutti i canali</option>
        <option class="px-4" *ngFor="let canale of canali" [value]="canale.id">{{canale.nome}}</option>
      </select>
    </div>
    <div class="lg:w-3/12 w-full">
      <select name="canale" class="h-full ricerca ricerca-select focus-visible:outline-none px-4 py-1.5" (change)="onFiltro()" [formControl]="categoriaFilter">
        <option class="px-4" value="-1" selected>Tutte le categorie</option>
        <option class="px-4" *ngFor="let categoria of categorie" [value]="categoria.id">{{categoria.titolo}}</option>
      </select>
    </div>
    <div class="lg:w-2/12 w-full">
      <button nz-button nzSize="large" class="bottone px-4 !w-full py-1.5 whitespace-break-spaces" type="button" (click)="onClearFiltri()">Pulisci i filtri</button>
    </div> -->
  </div>
  <!-- FILTRI -->

  <!-- LOADER -->
  <app-video-loading class="flex items-center justify-center my-8" *ngIf="loading"></app-video-loading>  
  <!-- LOADER -->

  <ng-container *ngIf="!loading">

    <!-- NESSUN RISULTATO -->    
    <h5 class="mt-8 flex justify-center items-center text-white font-semibold lg:text-2xl text-xl" *ngIf="ondemands_filtrati.length < 1">La ricerca non ha prodotto nessun risultato</h5>    
    <!-- NESSUN RISULTATO -->

    <!-- ON DEMANDS -->
    <div class="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4 gap-y-8 my-8" *ngIf="ondemands_filtrati.length > 0">
      <ng-container *ngFor="let ondemand of ondemands_filtrati.slice(0,num_ondemands);">
        <app-video-thumbnail (click)="onOndemand(ondemand.id)" [ondemand]="ondemand"></app-video-thumbnail>
      </ng-container>
      <ng-container *ngIf="num_ondemands < ondemands_filtrati.length">
        <div class="aspect-[16/9] object-cover flex justify-center overflow-hidden cursor-pointer transition-all duration-200 flex-col basis-[32%] animate-pulse bg-gray-500" *ngFor="let number of [1,2,3,4,5]"></div>
      </ng-container>
    </div>
    <!-- ON DEMANDS -->    

  </ng-container>

</div>