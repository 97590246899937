<nz-card [nzActions]="[actionClose, actionAdd]">    
    <form [formGroup]="formCategoria" (ngSubmit)="onSubmit()" id="formCategoria" class="flex flex-col gap-4">

        <nz-alert [nzType]="alertType" nzShowIcon [nzMessage]="alertMessage" *ngIf="alertVisible"></nz-alert>
        
        <nz-form-item>
            <nz-form-label nzRequired>Titolo</nz-form-label>
            <nz-form-control>
                <input nz-input nzSize="large" formControlName="titolo" required />
            </nz-form-control>
        </nz-form-item>            
        <nz-form-item>
            <nz-form-label>Descrizione</nz-form-label>
            <nz-form-control>
                <textarea nz-input nzSize="large" formControlName="descrizione"></textarea>
            </nz-form-control>
        </nz-form-item>  
                                          
    </form>  
</nz-card>
<ng-template #actionClose>
    <button type="button" nz-button nzSize="large" (click)="onClose()">Annulla</button>
</ng-template>
<ng-template #actionAdd>
    <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formCategoria">Aggiungi</button>
</ng-template>