<ng-template #categoriaTitle>
    <h3 class="text-white mb-0 font-semibold text-xl">Modifica categoria</h3>
</ng-template>    
<nz-card [nzTitle]="categoriaTitle" [nzActions]="[actionClose, actionEdit]">
    
    <form [formGroup]="formCategoria" (ngSubmit)="onSubmit()" id="formCategoria" class="flex flex-col gap-4">                                                 
        <nz-form-item>
            <nz-form-label nzRequired>Titolo</nz-form-label>
            <nz-form-control>
                <input nz-input nzSize="large" formControlName="titolo" required />
            </nz-form-control>
        </nz-form-item>
        <nz-form-item>
            <nz-form-label>Descrizione</nz-form-label>
            <nz-form-control>
                <textarea nz-input nzSize="large" formControlName="descrizione"></textarea>
            </nz-form-control>
        </nz-form-item>                    
    </form>
</nz-card>    
<ng-template #actionClose>
    <button nz-button nzSize="large" type="button" (click)="onAnnulla()">Annulla</button>        
</ng-template>
<ng-template #actionEdit>
    <button nz-button nzSize="large" [nzLoading]="loading" type="submit" class="home-button" form="formCategoria">Modifica</button>
</ng-template>