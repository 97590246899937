<!-- ONDEMANDS HOME PAGE -->
<div class="ondemands_home" *ngIf="ondemands_home">

    <!-- SLIDER -->
    <app-slider [navigation]="false" [loop]="false" [centeredSlides]="false" [grabCursor]="true"
    [breakpoints]="{'0.00': {slidesPerView: 1, spaceBetween: 10}, '@0.75': {slidesPerView: 2, spaceBetween: 10}, '@1.00': { slidesPerView: 3, spaceBetween: 10 }, '@1.50': { slidesPerView: 5, spaceBetween: 10 }}"
        [sliderLength]="ondemands_home.video.length">
        <swiper-slide [lazy]="true" *ngFor="let ondemand of ondemands_home.video;" class="h-auto">
            <app-video-thumbnail (click)="onOndemand(ondemand.id)" [ondemand]="ondemand"></app-video-thumbnail>
        </swiper-slide>
    </app-slider>
    <!-- SLIDER -->

</div>
<!-- ONDEMANDS HOME PAGE -->

<!-- ONDEMANDS -->
<div class="ondemands" *ngIf="ondemands.length > 0">

    <!-- SLIDER -->
    <app-slider [navigation]="false" [loop]="false" [centeredSlides]="false" [grabCursor]="true"
    [breakpoints]="{'0.00': {slidesPerView: 1, spaceBetween: 10}, '@0.75': {slidesPerView: 2, spaceBetween: 10}, '@1.00': { slidesPerView: 3, spaceBetween: 10 }, '@1.50': { slidesPerView: 5, spaceBetween: 10 }}"
        [sliderLength]="ondemands.length">
        <swiper-slide [lazy]="false" *ngFor="let ondemand of ondemands;" class="h-auto">
            <app-video-thumbnail (click)="onOndemand(ondemand.id)" [ondemand]="ondemand"></app-video-thumbnail>
        </swiper-slide>
    </app-slider>
    <!-- SLIDER -->

</div>
<!-- ONDEMANDS -->