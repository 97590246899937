import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Ondemand } from '../../shared/interfacce.model';
import { ApiService } from '../../services/api.service';
import { MediaPlayerElement } from 'vidstack/elements';

@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PlayerComponent implements OnInit, AfterViewInit {

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) { }

  @ViewChild('video') video?: ElementRef<MediaPlayerElement>;

  currentVideo: { src: string, poster: string, titolo: string, canale: string, descrizione: string, categoria: string, data: Date } = { src: '', descrizione: '', poster: '', titolo: '', canale: '', categoria: '', data: new Date() };

  loading: boolean = true;

  ondemand?: Ondemand;

  ngOnInit(): void {

    // Recupero i parametri passati nell'url
    this.route.params.subscribe(params => {

      const string = params['id'];
      const id = atob(string).split('id_ondemand=')[1];

      if (id) {
        // Recupero i dati del video ondemand passato
        this.apiService.getOndemandHome(+id).subscribe({
          next: (data) => this.ondemand = data,
          error: () => this.apiService.error("<p><strong>Il video Ondemand non è stato caricato correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>"),
          complete: () => {
            this.getDataVideo(this.ondemand!)
          }
        })
      } else {
        this.router.navigate(['/ondemand'])
      }
    })


  }

  ngAfterViewInit(): void {

    if (this.video) {
      this.video.nativeElement.viewType = 'video';
      this.video.nativeElement.muted = false;
      this.video.nativeElement.preload = 'auto';
      this.video.nativeElement.hideControlsOnMouseLeave = true;
      this.video.nativeElement.fullscreenOrientation = 'landscape';
      this.video.nativeElement.streamType = 'on-demand';
      this.video.nativeElement.playsInline = true;
      this.video.nativeElement.crossOrigin = true;
    }

  }

  getDataVideo(ondemand: Ondemand) {

    // Recupero le informazioni e le imposto per il live in esposizione    
    this.currentVideo = { descrizione: ondemand.descrizione, src: ondemand.cloudflare_video_url, poster: ondemand.cloudflare_image_url, titolo: ondemand.titolo, canale: ondemand.canale, categoria: ondemand.categoria, data: ondemand.data_aggiornamento };

    if (this.video) {
      this.video.nativeElement.title = this.currentVideo.titolo;
      this.video.nativeElement.src = this.currentVideo.src;
      this.video.nativeElement.poster = this.currentVideo.poster;
    }

    this.loading = false;

  }

  onExit() {
    this.router.navigate(['/ondemand'])
  }
}
