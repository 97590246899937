<div class="xl:px-container lg:px-16 px-8">

  <!-- LOGO MOBILE -->
  <div class="lg:hidden flex justify-center py-8">
    <img src="../../../assets/images/Logo bianco.png" height="40px" alt="Logo 7Live">
  </div>
  <!-- LOGO MOBILE -->

</div>

<!-- LIVE -->
<app-player-evidenza></app-player-evidenza>
<!-- LIVE -->  

<div class="xl:px-container lg:px-16 px-8">  

  <!-- CANALI IN EVIDENZA -->
  <!-- <div class="canali-evidenza" *ngIf="canali_evidenza.length > 0 && !loading" [@fadeInOut]>    
    <h3 class="text-white font-bold lg:text-5xl text-3xl my-8 z-10 relative">Canali in primo piano</h3>              
    <app-galleria-canali [canali]="canali_evidenza" [evidenza]="true" class="relative"></app-galleria-canali>          
  </div> -->
  <!-- CANALI IN EVIDENZA -->

  <!-- TUTTI I CANALI -->
  <div class="canali" *ngIf="!loading" [@fadeInOut]>    
    <h3 class="text-white font-bold lg:text-4xl text-3xl mt-16 mb-8 z-10 relative">I nostri canali</h3>        
    <h3 class="text-white flex items-center justify-center lg:text-2xl text-xl my-8" *ngIf="canali.length <= 0">Nessun canale caricato</h3>              
    <app-galleria-canali [canali]="canali" class="relative" *ngIf="canali.length > 0"></app-galleria-canali>        
  </div>
  <!-- TUTTI I CANALI -->

  <!-- CATEGORIE ON DEMAND -->
  <div class="categorie-ondemand" *ngIf="!loading" [@fadeInOut]>
    <div class="ondemands" *ngFor="let ondemand of ondemands">
      <ng-container *ngIf="ondemand.video.length > 0" >        
        <h3 class="text-white font-bold lg:text-4xl text-3xl mt-16 mb-8">{{ondemand.categoria == 'Generale' ? 'Guarda anche' : ondemand.categoria }}</h3>        
        <app-galleria-video [ondemands_home]="ondemand"></app-galleria-video>        
      </ng-container>
    </div>
  </div>
  <!-- CATEGORIE ON DEMAND -->

</div>

<!-- BANNER INFO -->
<div class="info lg:my-16 my-8 relative xl:px-container lg:px-16 px-8 lg:pe-0 flex lg:flex-row justify-center flex-col gap-4" *ngIf="!loading" [@fadeInOut]>  
  <div class="lg:w-1/2 w-full px-8 py-8 lg:my-auto mb-8 flex flex-col gap-4">      
    <h2 class="text-white font-bold text-3xl">Guarda tutto ciò che desideri, <br> quando lo vuoi tu</h2>
    <ng-container *ngIf="abbonamento">
      <p class="text-xl">Visita l'area On Demand per guardare tutti i nostri video disponibili!</p>
      <button class="bottone mt-4 w-fit" nzType="primary" nz-button [routerLink]="'/ondemand'">ON DEMAND</button>
    </ng-container>
    <ng-container *ngIf="!abbonamento">
      <p>Abbonati ora a Seven Live Web TV premendo il pulsante sottostante o accedendo alla tua area personale!</p>
      <button class="bottone mt-4 w-fit" nzType="primary" nz-button [routerLink]="user?.id ? '/profilo/abbonamento' : '/login'">ABBONATI</button>            
    </ng-container>
    <div class="flex flex-col md:flex-row my-4 gap-4">
      <a href="https://play.google.com/store/apps/details?id=com.sevenlive.app" target="_blank">
        <img class="block h-auto w-52 md:h-16" src="../../../assets/images/GetItOnGooglePlay_Badge_Web_color_Italian.png" alt="Scarica l'app Android">
      </a>
      <a href="https://apps.apple.com/us/app/7live-tv/id6667118264" target="_blank">
        <img class="block h-auto w-52 md:h-16" src="../../../assets/images/Download_on_the_App_Store_Badge_IT_RGB_blk_100317.svg" alt="Scarica l'app iOS">
      </a>
    </div>
  </div>
  <div class="lg:w-1/2 w-full sfondo lg:bg-contain bg-cover bg-no-repeat lg:bg-right relative lg:h-full h-1/2"> </div>  
</div>
<!-- BANNER INFO -->

<!-- NEWS -->
<div class="xl:px-container lg:px-16 px-8" *ngIf="notizie.length > 0 && !loading" [@fadeInOut]>  
  <h3 class="text-white font-bold lg:text-4xl text-3xl lg:mt-16 mb-8 my-8 block">News</h3>  
  <app-notizia *ngFor="let notizia of notizie.slice(0,5); let i = index" [notizia]="notizia" [notizie]="notizie" [index]="i"></app-notizia>
  <button routerLink="/news" *ngIf="notizie.length > 5" class="bottone mt-4 block mx-auto" nzType="primary" nz-button>Vedi tutte</button>
</div>
<!-- NEWS -->