<div class="relative">

    <media-player #video oncontextmenu="return false;" controls="false" muted class="evidenza-player" log-level="warn">
        <media-provider>
            <media-poster class="evidenza-player-poster" [src]="currentvideo.poster + '?time=0h0m05s'"></media-poster>
        </media-provider>
        <media-video-layout></media-video-layout>
    </media-player>

    <div class="xl:px-container lg:px-16 px-8 absolute lg:top-[75%] lg:-translate-y-[75%] top-[85%] -translate-y-[85%] z-2">
        <h3 class="testo text-white font-bold lg:text-3xl md:text-2xl md:w-2/3 fade-in">{{currentvideo.titolo}}</h3>
        <div class="flex flex-row lg:justify-start justify-center gap-4 mt-4 fade-in-2nd">
            <button class="border-2 border-white text-white transition-all duration-300 ease-in-out font-bold flex items-center px-4 hover:text-accent hover:bg-white" [routerLink]="currentvideo.link"><i class="bi bi-caret-right-fill lg:text-2xl text-xl"></i> RIPRODUCI</button>
            <i class="bi text-3xl aspect-square rounded-full cursor-pointer h-12 w-12 border-2 border-white text-white transition-all duration-300 ease-in-out font-bold lg:flex hidden items-center justify-center px-4 hover:text-accent hover:bg-white" [ngClass]="{'bi-volume-mute-fill': muted, 'bi-volume-up-fill': !muted}" (click)="onMute(muted)"></i>
        </div>
    </div>

    <div class="md:block scroll-cta hidden">
        <i class="bi-arrow-down bi text-4xl"></i>
    </div>
</div>