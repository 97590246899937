<img class="bg" #img [src]="canale?.copertina ? (canale?.copertina | image) : '../../../assets/images/default_copertina.png'" (error)="img.src = '../../../assets/images/default_copertina.png'" alt="Copertina Canale">
<div class="overlay">        
    <div class="immagine vrappatore">
        <img #icon [src]="canale?.icona ? (canale?.icona | image) : ''" class="watermark" alt="Icona" *ngIf="canale?.icona" (error)="icon.style.display = 'none'">
    </div>
    <div class="testi w-full">        
        <div class="vrappatore">
            <div class="descrizione">
                <h5 id="titolo" class="p-0 m-0 text-[16px] font-semibold text-white testo crop" [attr.title]="canale?.nome">{{canale?.nome}}</h5>
                <!-- <p id="desc" class="p-0 m-0 text-white testo crop">{{canale?.descrizione}}</p> -->
            </div>
        </div>
    </div>
</div>