import { Component, ViewContainerRef } from '@angular/core';
import { ApiService } from '../../../../services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ImmaginiNews } from '../../../../shared/interfacce.model';
import { environment } from '../../../../../environments/environment.development';
import { NzModalService } from 'ng-zorro-antd/modal';
import { EliminaImmagginiNewsComponent } from '../elimina-immaggini-news/elimina-immaggini-news.component';

@Component({
  selector: 'app-aggiungi-immagini-news-backoffice',
  templateUrl: './aggiungi-immagini-news-backoffice.component.html',
  styleUrl: './aggiungi-immagini-news-backoffice.component.css'
})
export class AggiungiImmaginiNewsBackofficeComponent {

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  immagini: ImmaginiNews[] = [];

  files: File[] = [];
  imageList: NzUploadFile[] = [];

  id: number = 0;

  loading: boolean = false;
  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  apiUrl: string = environment.apiUrl;

  ngOnInit(): void {
    // Recupero i parametri nell'url
    this.route.params.subscribe(params => {

      this.id = params['id']

      this.apiService.setImmaginiNews(this.id);
      this.apiService.immaginiNewsChanged.subscribe({
        next: (data) => this.immagini = [...data],
        error: (err) => this.onError(err.error.message)      
      })

    });
  }

  onClose() {
    this.router.navigate(['/backoffice/news']);
  }

  beforeImageUpload = (file: any): boolean => {

    this.imageList = this.imageList.concat(file);

    // Controllo se l'immagine caricata ha il formato JPG o PNG
    const isJpgOrPng = this.imageList.length > 0 ? this.imageList[0]!.type === 'image/jpeg' || this.imageList[0]!.type === 'image/png' : true;

    // Se non ha il formato corretto scarto il file
    if (!isJpgOrPng) {
      this.imageList = [];
      this.onError("L'immagine deve essere di tipo JPG, PNG o JPEG")
      return false;
    } else {
      this.files = this.files.concat(file);
      return false;
    }
  };

  removeImageUpload = (event: any): boolean => {
    // Rimuovo il file dal form
    this.files = this.files.filter(file => event != file);
    this.imageList = this.imageList.filter(file => event != file);;
    return false;
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onEliminaImmagine(immagine: string, id_news: number, id: number) {
    // Formo il modal per l'eliminazione dell'immagine della news
    this.modal.create({
      nzTitle: 'Conferma',
      nzContent: EliminaImmagginiNewsComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzData: {   
        copertina: false,     
        nome_immagine: immagine,        
        id_news: id_news,
        id_immagine: id
      },
      nzFooter: null,
      nzCentered: true,
      nzWidth: 750,
      nzBodyStyle: {'padding': '0px'}
    });    
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta dell'api
    const formData = new FormData();
    
    this.files.map(file => formData.append('immagine', file || ''))    

    this.loading = true;

    // Aggiungo la news al db
    this.apiService.addImmaginiNews(this.id, formData).subscribe({
      next: (data) => this.apiService.setImmaginiNews(this.id),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.files = [];
        this.imageList = [];
        // this.router.navigate(['/backoffice/news']);
      }
    })

  }
}
