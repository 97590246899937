<div class="flex justify-center items-center xl:px-container lg:px-16 px-8 h-[100vh]">    
    <form [formGroup]="formLogin" (ngSubmit)="onSubmit()" class="lg:w-1/2 w-full flex flex-col gap-4">                
        <div class="form-image flex justify-center">
            <img src="../../../assets/images/logo_scuro.png" alt="logo Seven Live Web TV">
        </div>

        <nz-alert nzType="error" [nzMessage]="'Credenziali errate, riprovare!'" nzShowIcon nzCloseable *ngIf="error"></nz-alert>

        <nz-form-item>
            <nz-form-label nzRequired>Username</nz-form-label>
            <nz-form-control>
                <input nz-input nzSize="large" formControlName="username" required/>
            </nz-form-control>
        </nz-form-item>                                
        <ng-container *ngFor="let error of error_messages.username">
            <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formLogin.get('username')?.hasError(error.type) && (formLogin.get('username')?.dirty || formLogin.get('username')?.touched)"></nz-alert>
        </ng-container>  

        <nz-form-item>
            <nz-form-label nzRequired>Password</nz-form-label>
            <nz-form-control>
                <nz-input-group [nzSuffix]="passwordTemplate" nzSize="large">
                    <input [type]="hide ? 'text' : 'password'" nz-input formControlName="password" required />
                </nz-input-group>
                <ng-template #passwordTemplate>
                    <span nz-icon [nzType]="hide ? 'eye-invisible' : 'eye'" (click)="hide = !hide"></span>
                </ng-template>
            </nz-form-control>
        </nz-form-item>
                    
        <ng-container *ngFor="let error of error_messages.password">                                    
            <nz-alert nzType="error" [nzMessage]="error.message" nzShowIcon nzCloseable *ngIf="formLogin.get('password')?.hasError(error.type) && (formLogin.get('password')?.dirty || formLogin.get('password')?.touched)"></nz-alert>
        </ng-container>
                     
        <button class="home-button w-full mt-4" nzType="primary" nz-button nzSize="large" type="submit" [disabled]="!formLogin.valid">Login</button>                               
    </form>        
</div>