import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzUploadFile } from 'ng-zorro-antd/upload';
import { ModalAggiungiCanaleBackofficeComponent } from '../../canali-backoffice/modal-aggiungi-canale-backoffice/modal-aggiungi-canale-backoffice.component';
import { ModalAggiungiCategoriaBackofficeComponent } from '../../categorie-backoffice/modal-aggiungi-categoria-backoffice/modal-aggiungi-categoria-backoffice.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ApiService } from '../../../../services/api.service';
import { StorageService } from '../../../../services/storage.service';
import { VideoService } from '../../../../services/video.service';
import { Canale, Categoria, Ondemand, UserBackoffice } from '../../../../shared/interfacce.model';
import { environment } from '../../../../../environments/environment.development';

import Uppy from '@uppy/core';
import Tus from '@uppy/tus'
import it_IT from "@uppy/locales/lib/it_IT.js"

@Component({
  selector: 'app-modifica-ondemand-backoffice',
  templateUrl: './modifica-ondemand-backoffice.component.html',
  styleUrls: ['./modifica-ondemand-backoffice.component.css']
})
export class ModificaOndemandBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private storageService: StorageService, private router: Router, private route: ActivatedRoute, private videoService: VideoService, private modal: NzModalService, private viewContainerRef: ViewContainerRef) { }

  formOndemand!: FormGroup;

  canali: Canale[] = [];
  categorie: Categoria[] = [];
  ondemand?: Ondemand;

  video_name: string = '';
  copertina: string = '';

  imageList: NzUploadFile[] = [];

  loading: boolean = false;
  alertVisible: boolean = false;
  new_video: boolean = false;

  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';

  user!: UserBackoffice;

  ngOnInit(): void {

    // Recupero l'utente loggato nel backoffice
    this.user = this.storageService.getUserBackoffice();

    this.formOndemand = new FormGroup({
      titolo: new FormControl(null),
      descrizione: new FormControl(null),
      id_canale: new FormControl(null),
      id_categoria: new FormControl(null),
      abbonamento: new FormControl(null),
      copertina: new FormControl(null),
      video: new FormControl(null)
    })

    // Recupero l'id dall'url
    this.route.params.subscribe(params => {
      let id = params['id'];

      // Recupero i dati dell'ondemand
      this.apiService.getOndemand(id).subscribe({
        next: (data) => this.ondemand = data,
        error: (err) => this.router.navigate(['/backoffice/ondemand']),
        complete: () => {

          // Recupero le categorie
          this.apiService.getCategorie().subscribe({
            next: (data) => this.categorie = [...data],
            error: (err) => this.onError("Categorie non recuperate correttamente, ricaricare la pagina o aspettare qualche secondo!"),
            complete: () => {

              // Recupero tutti i canali
              this.apiService.getCanali().subscribe({
                next: (data) => {

                  this.canali = [...data];

                  // Se l'utente è amministratore mostro tutti i canali
                  if (this.user.tipo !== 'Amministratore') {

                    // Se l'ondemand è di un canale diverso rispetto a quello collegato all'utente torno indietro
                    if (JSON.stringify(this.ondemand!.id_canale).indexOf(this.user.id_canale.toString()) <= -1) {
                      this.router.navigate(['/backoffice/ondemand'])
                    }

                    // Se non sei amministratore mostro solo il tuo canale e blocco la scelta
                    this.formOndemand.get('id_canale')?.disable();
                  }
                },
                error: (err) => this.onError("Canali non caricati correttamente, ricaricare la pagina o aspettare qualche secondo!"),
                complete: () => {

                  // Compongo il form con i dati dell'ondemand
                  this.formOndemand.setValue({
                    titolo: this.ondemand?.titolo,
                    descrizione: this.ondemand?.descrizione,
                    id_canale: this.ondemand?.id_canale.split(",").map(id => +id),
                    id_categoria: this.ondemand?.id_categoria.split(",").map(id => +id),
                    abbonamento: this.ondemand?.abbonamento ? true : false,
                    copertina: this.ondemand?.copertina,
                    video: this.ondemand?.cloudflare_video_url,
                  })

                }
              })

            }
          })

        }
      })

    });
  }

  beforeImageUpload = (file: any): boolean => {
    this.imageList = this.imageList.concat(file);
    const isJpgOrPng = this.imageList.length > 0 ? this.imageList[0]!.type === 'image/jpeg' || this.imageList[0]!.type === 'image/png' : true;

    if (!isJpgOrPng) {
      this.imageList = [];
      this.onError("L'immagine di copertina deve essere di tipo JPG, PNG o JPEG");
      return false;
    } else {

      const reader = new FileReader();
      reader.onload = () => {
        this.alertVisible = false;
        this.copertina = reader.result as string;
        this.formOndemand.patchValue({ copertina: file.name });
      }
      reader.readAsDataURL(file);

      return false;
    }
  };

  removeImageUpload = (file: any): boolean => {
    this.copertina = '';
    this.formOndemand.patchValue({ copertina: '' });
    this.imageList = [];
    return false;
  }

  onClose() {
    this.router.navigate(['/backoffice/ondemand']);
  }

  onCanale(canale: string) {
    // Se il canale selezionato è 'altro' procedo
    if (canale == '0') {

      // Creo il modal
      let modal = this.modal.create({
        nzTitle: 'Aggiungi Canale',
        nzContent: ModalAggiungiCanaleBackofficeComponent,
        nzViewContainerRef: this.viewContainerRef,
        nzFooter: null,
        nzCentered: true,
        nzWidth: 1250,
        nzBodyStyle: { 'padding': '0px' }
      });

      // Se il modal viene chiuso      
      modal.afterClose.subscribe(() => {

        // Recupero i canali
        this.apiService.getCanali().subscribe({
          next: (data) => this.canali = data,
          error: (err) => this.onError("Canali non caricati correttamente, ricaricare la pagina o aspettare qualche secondo!"),
          complete: () => this.formOndemand.patchValue({ id_canale: '' })
        })
      })
    }
  }

  onCategoria(categoria: string) {
    // Se la categoria selezionata è 'altro' procedo
    if (categoria == '0') {

      // Creo il modal
      let modal = this.modal.create({
        nzTitle: 'Aggiungi Categoria',
        nzContent: ModalAggiungiCategoriaBackofficeComponent,
        nzViewContainerRef: this.viewContainerRef,
        nzFooter: null,
        nzCentered: true,
        nzWidth: 1250,
        nzBodyStyle: { 'padding': '0px' }
      });

      // Se il modal viene chiuso
      modal.afterClose.subscribe(() => {

        // Recupero le categorie
        this.apiService.getCategorie().subscribe({
          next: (data) => this.categorie = [...data],
          error: (err) => this.onError("Categorie non caricati correttamente, ricaricare la pagina o aspettare qualche secondo!"),
          complete: () => this.formOndemand.patchValue({ id_categoria: '' })
        })

      })
    }
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta dell'api    
    let ondemand = {
      titolo: this.formOndemand.get('titolo')?.value,
      descrizione: this.formOndemand.get('descrizione')?.value,
      id_canali: this.formOndemand.get('id_canale')?.value ? this.formOndemand.get('id_canale')?.value : [],
      id_categorie: this.formOndemand.get('id_categoria')?.value ? this.formOndemand.get('id_categoria')?.value : [],
      abbonamento: this.formOndemand.get('abbonamento')?.value || false,
      copertina: this.formOndemand.get('copertina')?.value,
      copertina_file: this.copertina || '',
    }

    this.loading = true;

    // Attuo i controlli per vedere se sono stati caricati tutti i campi richiesti
    if (!ondemand.titolo) {
      this.onError("Inserire un titolo per procedere!");
    } else if (!ondemand.id_categorie) {
      this.onError("Inserire un canale per procedere!");
    } else if (!ondemand.id_categorie) {
      this.onError("Inserire una categoria per procedere!");
    } else {

      // Aggiorno l'ondemand sul db
      this.apiService.updateOndemand(this.ondemand!.id, ondemand).subscribe({
        next: (data) => this.apiService.setOndemand(),
        error: (err) => this.onError(err.error.message + ' Ricaricare la pagina, per procedere al caricamento del video!'),
        complete: () => {
          this.loading = false;
          this.router.navigate(['/backoffice/ondemand']);
        }
      })
    }
  }
}
