<div class="header-placeholder" *ngIf="!mobile"></div>
<header class="header" id="header" *ngIf="!mobile">
  <div class="flex h-full xl:px-container lg:px-16 px-8 mx-auto header-content justify-between">
    <img src="../../../assets/images/Logo bianco.png" class="logo-image" routerLink="/home">
    <menu>
      <ul>
        <div class="pagine flex h-full">
          <li class="links" routerLinkActive="active-link" routerLink="/home"><span class="flex items-center h-full">Home</span></li>
          <li class="links" routerLinkActive="active-link" routerLink="/live"><span class="flex items-center h-full">Live</span></li>
          <li class="links" routerLinkActive="active-link" [ngClass]="{'active-link': ondemandActive.isActive || abbonamentoActive.isActive, 'active-links': !ondemandActive.isActive}" routerLink="/ondemand">
            <span class="flex items-center h-full">On Demand</span>
          </li>
          <li class="links !hidden" routerLink="/ondemand" routerLinkActive #ondemandActive="routerLinkActive"></li>
          <li class="links !hidden" routerLink="/abbonamento" routerLinkActive #abbonamentoActive="routerLinkActive"></li>
          <li class="links" routerLinkActive="active-link" routerLink="/news"><span class="flex items-center h-full">News</span></li>          
          <li class="links" routerLinkActive="active-link" [routerLink]="user?.id ? '/profilo' : '/login'"><span class="flex items-center h-full">{{user?.id ? 'Ciao, ' + user?.nome : 'Accedi'}}</span></li>
        </div>                
      </ul>
    </menu>
  </div>
</header>

<div class="nav-mobile" *ngIf="mobile">
  <div class="nav-item" routerLink="/live" routerLinkActive="active">
    <i class="material-icons">live_tv</i>
    <span>Live</span>
  </div>

  <div class="nav-item" routerLink="/ondemand" routerLinkActive="active" [ngClass]="{'active': ondemandMobileActive.isActive || abbonamentoMobileActive.isActive, 'active-links': !ondemandMobileActive.isActive }">
    <i class="material-icons">ondemand_video</i>
    <span>On Demand</span>
  </div>
  
  <div class="nav-item !hidden" routerLink="/ondemand" routerLinkActive #ondemandMobileActive="routerLinkActive"></div>
  <div class="nav-item !hidden" routerLink="/abbonamento" routerLinkActive #abbonamentoMobileActive="routerLinkActive"></div>

  <div class="nav-item" routerLink="/home" routerLinkActive="active">
    <i class="material-icons">home</i>
    <span>Home</span>
  </div>      
  <div class="nav-item" routerLink="/news" routerLinkActive="active">
    <i class="material-icons">newspaper</i>
    <span>News</span>
  </div>
  <div class="nav-item" routerLink="profilo" routerLinkActive="active">
    <i class="material-icons">person_pin</i>
    <span>Profilo</span>
  </div>
</div>
<div id="contenuto">
    <router-outlet></router-outlet>
    <app-cookies></app-cookies>
</div>

<!-- LOADER -->
<div class="loading h-full-nav absolute top-0 z-20 liveContainer" *ngIf="loading">  
  <img src="../../../assets/images/Logo bianco.png" height="40px" alt="Logo 7Live" class="logo animate-pulse">
</div>
<!-- LOADER -->

<app-footer></app-footer>