import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment.development';

@Pipe({
  name: 'image'
})
export class ImagePipe implements PipeTransform {

  api_url: string = environment.apiUrl;

  transform(value: unknown, ...args: unknown[]): unknown {
    return `${this.api_url}immagine/${value}`;
  }

}
