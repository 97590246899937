import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '../../services/api.service';
import { Canale, Ondemand } from '../../shared/interfacce.model';
import { environment } from '../../../environments/environment.development';

@Component({
  selector: 'app-canale',
  templateUrl: './canale.component.html',
  styleUrls: ['./canale.component.css'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class CanaleComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router, private route: ActivatedRoute) { }

  canale?: Canale;
  ondemands: Ondemand[] = [];

  ondemandLimit: number = 10;

  loading: boolean = true;

  @HostListener("window:scroll", [])
  onScroll(): void {        
    if (document.body.scrollHeight - (window.innerHeight + window.scrollY) == 0) {      
      setTimeout(() => { this.onMostra(10); }, 1000);
    }
  }

  ngOnInit(): void {

    // Recupero l'id passato nell'url del canale
    let name = this.route.snapshot.paramMap.get('canale')!;

    // Recupero i dati del canale
    this.apiService.getCanaleByName(name).subscribe({
      next: (data) => this.canale = data,
      error: (err) => {
        this.loading = false;
        this.apiService.error("<p><strong>Il canale non è stato caricato correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
      },
      complete: () => {        

        // Recupero gli ondemand del canale
        this.apiService.getOndemandsCanale(this.canale!.id).subscribe({          
          next: (data) => this.ondemands = [...data.filter(ondemand => ondemand.cloudflare_mp4_url != null)],
          error: (err) => {
            this.loading = false;
            this.apiService.error("<p><strong>I video Ondemand non sono stati caricati correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
          },
          complete: () => this.loading = false
        })

      }
    })
  }

  onMostra(increment: number) {
    this.ondemandLimit += increment;
  }

  onOndemand(id: number) {
    this.router.navigate(['/ondemand/' + btoa("id_ondemand=" + id)]);
  }

}
