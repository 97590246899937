import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { ApiService } from '../../services/api.service';
import { StorageService } from '../../services/storage.service';
import { Canale, News, OndemandHome, User } from '../../shared/interfacce.model';
import { MediaPlayerElement } from 'vidstack/elements';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ],
})
export class HomeComponent implements OnInit {

  constructor(private apiService: ApiService, private storageService: StorageService) { }

  @ViewChild('video') video?: ElementRef<MediaPlayerElement>

  canali: Canale[] = [];
  canali_evidenza: Canale[] = [];

  notizie: News[] = [];
  ondemands: OndemandHome[] = [];

  abbonamento: boolean = false;
  loading: boolean = true;

  user?: User;

  ngOnInit(): void {

    this.loading = true;

    // Recupero l'utente loggato
    this.user = this.storageService.getUser();

    // Recupero i canali
    this.apiService.getCanali().subscribe({
      next: (data) => this.canali = [...data.filter(canale => canale.videos > 0)],
      error: (err) => {
        this.loading = false;
        this.apiService.error("<p><strong>I canali non sono stati caricati correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
      },
      complete: () => {

        // Ottengo i canali in evidenza
        this.canali_evidenza = this.canali.filter(canale => canale.evidenza == true && canale.videos > 0);

        // Recupero le news
        this.apiService.getNotizie().subscribe({
          next: (data) => this.notizie = [...data],
          error: (err) => {
            this.loading = false;
            this.apiService.error("<p><strong>Le news non sono state caricate correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
          },
          complete: () => {

            // Recupero gli ondemand suddivisi per categoria
            this.apiService.getOndemandsCategorie().subscribe({
              next: (data) => this.ondemands = [...data.filter(ondemand => ondemand.video.filter(video => video.cloudflare_mp4_url != null))],
              error: (err) => {
                this.loading = false;
                this.apiService.error("<p><strong>I video Ondemand non sono stati caricati correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
              },
              complete: () => {

                if (this.user) {
                  // Recupero l'abbonamento del cliente se presente
                  this.apiService.getAbbonamentoCliente(this.user.id).subscribe({
                    next: (data) => data ? this.abbonamento = true : this.abbonamento = false,
                    error: (err) => {
                      this.loading = false;
                      this.apiService.error("<p><strong>L'abbonamento non è stato caricato correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>")
                    },
                    complete: () => this.loading = false
                  })
                } else {
                  this.loading = false;
                }
              }
            })
          }
        })
      }
    })

  }

}
