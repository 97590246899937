<div class="xl:px-container lg:px-16 px-8">

  <!-- LOGO MOBILE -->
  <div class="lg:hidden flex justify-center py-8">
    <img src="../../assets/images/Logo bianco.png" height="40px" alt="Logo Seven Live">
  </div>
  <!-- LOGO MOBILE -->

</div>
<!-- LIVE  -->
<div class="liveContainer">

  <!-- BG GRADIENT  -->
  <div class="gradiente"></div>
  <!-- BG GRADIENT  -->

  <!-- PLAYER -->
  <div class="xl:px-container lg:px-16 px-8 flex flex-col gap-8">
    <h3 class="text-white font-bold lg:text-4xl text-3xl w-full mt-16">Cerca il canale che ti piace di più</h3>    
    <app-player-live></app-player-live>
  </div>
  <!-- PLAYER -->
</div>
<!-- LIVE  -->