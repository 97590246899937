import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-download',
  templateUrl: './download.component.html',
  styleUrl: './download.component.css'
})
export class DownloadComponent {

  constructor(private router: Router) { } 

  ngOnInit(): void {
    /* if(/Macintosh|Windows/i.test(navigator.userAgent) ) {
      this.router.navigate(['/home']);
      return;
    } */
    
    if(/iPhone|iPad|iPod/i.test(navigator.userAgent) ) {
      window.location.href = 'https://apps.apple.com/us/app/7live-tv/id6667118264';
    }
    if(/Android/i.test(navigator.userAgent) ){
      window.location.href = 'https://play.google.com/store/apps/details?id=com.sevenlive.app';
    }
  }


}
