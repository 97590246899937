import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Ondemand, OndemandHome } from '../../shared/interfacce.model';

@Component({
  selector: 'app-raccolta',
  templateUrl: './raccolta.component.html',
  styleUrl: './raccolta.component.css'
})
export class RaccoltaComponent {

  @Input() ondemands: Ondemand[] = [];
  @Input() currVideo: number | undefined = undefined;

  @Input() id: number = 1;
  @Input() nome: string = "Raccolta 1";
  @Input() desc: string = "Descrizione della bellissima raccolta wow che bella raccolta";

  constructor(private router: Router) { }  

  onOndemand(id: number) {
    this.router.navigate(['/ondemand/' + btoa("id_ondemand=" + id)]);
    /* this.router.navigate(['/ondemand/' + btoa("id_ondemand=" + id) + '/?r=' + this.id]); */
  }

}
