import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { DatePipe, registerLocaleData } from '@angular/common';
import it from '@angular/common/locales/it';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient } from '@angular/common/http';
import { environment } from '../environments/environment.development';
import { ApiInterceptor } from './services/api.service';

// NGZORRO
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NZ_I18N, it_IT, NZ_DATE_LOCALE } from 'ng-zorro-antd/i18n';
import { it as IT } from 'date-fns/locale';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzTimePickerModule } from 'ng-zorro-antd/time-picker';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzImageModule } from 'ng-zorro-antd/image';
// NGZORRO

// UPPY
import { UppyAngularDashboardModule } from '@uppy/angular';
// UPPY

// SWIPER
import { register as registerSwiperElement } from 'swiper/element/bundle';
// SWIPER

// RECAPTCHA
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
// RECAPTCHA

// PIPE
import { SearchCanaleLivePipe, SearchCanaleOndemandPipe, SearchClienteAbbonamentoPipe, SearchClienteTicketPipe, SearchDataNewsPipe, SearchDescrizioneCanalePipe, SearchEmailClientePipe, SearchNomeCognomeClientePipe, SearchStatoAbbonamentoPipe, SearchStatoTicketPipe, SearchTipoUtentePipe, SearchTitoloCanalePipe, SearchTitoloCategoriaPipe, SearchTitoloLivePipe, SearchTitoloNewsPipe, SearchTitoloOndemandPipe, SearchUsernameUtentePipe } from './shared/search.pipe';
// PIPE

// COMMON
import { NotiziaComponent } from './common/notizia/notizia.component';
import { HeaderComponent } from './common/header/header.component';
import { FooterComponent } from './common/footer/footer.component';
import { PlayerComponent } from './common/player/player.component';
import { PlayerEvidenzaComponent } from './common/player-evidenza/player-evidenza.component';
import { VideoLoadingComponent } from './common/video-loading/video-loading.component';
import { PlayerLiveComponent } from './common/player-live/player-live.component';
import { ErrorComponent } from './common/error/error.component';
import { CookiesComponent } from './common/cookies/cookies.component';
import { VideoThumbnailComponent } from './common/video-thumbnail/video-thumbnail.component';
import { GalleriaVideoComponent } from './common/galleria-video/galleria-video.component';
import { GalleriaCanaliComponent } from './common/galleria-canali/galleria-canali.component';
import { CanaleThumbnailComponent } from './common/canale-thumbnail/canale-thumbnail.component';
import { NessunAbbonamentoComponent } from './common/nessun-abbonamento/nessun-abbonamento.component';
import { SliderComponent } from './common/slider/slider.component';
// COMMON

// PAGES
import { HomeComponent } from './pages/home/home.component';
import { PrivacyComponent } from './pages/privacy/privacy.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { ResetComponent } from './pages/reset/reset.component';
import { RegistrazioneComponent } from './pages/registrazione/registrazione.component';
import { LoginComponent } from './pages/login/login.component';
import { ContattaciComponent } from './pages/contattaci/contattaci.component';
import { OnDemandComponent } from './pages/on-demand/on-demand.component';
import { CanaleComponent } from './pages/canale/canale.component';
import { NewsComponent } from './pages/news/news.component';
import { SingolaNewsComponent } from './pages/news/singola-news/singola-news.component';
import { CondizioniComponent } from './pages/condizioni/condizioni.component';
import { ConfermaComponent } from './pages/conferma/conferma.component';
import { LiveComponent } from './pages/live/live.component';
import { DownloadComponent } from './pages/download/download.component';

// PROFILO
import { ProfiloComponent } from './pages/profilo/profilo.component';
import { TicketProfiloComponent } from './pages/profilo/ticket-profilo/ticket-profilo.component';
import { GestisciProfiloComponent } from './pages/profilo/gestisci-profilo/gestisci-profilo.component';
import { AbbonamentiProfiloComponent } from './pages/profilo/abbonamenti-profilo/abbonamenti-profilo.component';
import { PagamentoProfiloComponent } from './pages/profilo/pagamento-profilo/pagamento-profilo.component';
import { AnnulloPagamentoComponent } from './pages/profilo/annullo-pagamento/annullo-pagamento.component';
// PROFILO

// BACKOFFICE
import { LoginBackofficeComponent } from './pages/backoffice/login-backoffice/login-backoffice.component';
import { HomepageBackofficeComponent } from './pages/backoffice/homepage-backoffice/homepage-backoffice.component';
import { BackofficeComponent } from './pages/backoffice/backoffice.component';
import { HeaderBackofficeComponent } from './pages/backoffice/header-backoffice/header-backoffice.component';
import { CanaliBackofficeComponent } from './pages/backoffice/canali-backoffice/canali-backoffice.component';
import { LiveBackofficeComponent } from './pages/backoffice/live-backoffice/live-backoffice.component';
import { OndemandBackofficeComponent } from './pages/backoffice/ondemand-backoffice/ondemand-backoffice.component';
import { ClientiBackofficeComponent } from './pages/backoffice/clienti-backoffice/clienti-backoffice.component';
import { UtentiBackofficeComponent } from './pages/backoffice/utenti-backoffice/utenti-backoffice.component';
import { AbbonamentiBackofficeComponent } from './pages/backoffice/abbonamenti-backoffice/abbonamenti-backoffice.component';
import { TicketsBackofficeComponent } from './pages/backoffice/tickets-backoffice/tickets-backoffice.component';
import { AggiungiUtenteBackofficeComponent } from './pages/backoffice/utenti-backoffice/aggiungi-utente-backoffice/aggiungi-utente-backoffice.component';
import { ModificaUtenteBackofficeComponent } from './pages/backoffice/utenti-backoffice/modifica-utente-backoffice/modifica-utente-backoffice.component';
import { EliminaUtenteBackofficeComponent } from './pages/backoffice/utenti-backoffice/elimina-utente-backoffice/elimina-utente-backoffice.component';
import { AggiungiCanaleBackofficeComponent } from './pages/backoffice/canali-backoffice/aggiungi-canale-backoffice/aggiungi-canale-backoffice.component';
import { EliminaCanaleBackofficeComponent } from './pages/backoffice/canali-backoffice/elimina-canale-backoffice/elimina-canale-backoffice.component';
import { ModificaCanaleBackofficeComponent } from './pages/backoffice/canali-backoffice/modifica-canale-backoffice/modifica-canale-backoffice.component';
import { EliminaTicketBackofficeComponent } from './pages/backoffice/tickets-backoffice/elimina-ticket-backoffice/elimina-ticket-backoffice.component';
import { ModificaTicketBackofficeComponent } from './pages/backoffice/tickets-backoffice/modifica-ticket-backoffice/modifica-ticket-backoffice.component';
import { AggiungiTicketBackofficeComponent } from './pages/backoffice/tickets-backoffice/aggiungi-ticket-backoffice/aggiungi-ticket-backoffice.component';
import { ChiudiTicketBackofficeComponent } from './pages/backoffice/tickets-backoffice/chiudi-ticket-backoffice/chiudi-ticket-backoffice.component';
import { AggiungiClienteBackofficeComponent } from './pages/backoffice/clienti-backoffice/aggiungi-cliente-backoffice/aggiungi-cliente-backoffice.component';
import { EliminaClienteBackofficeComponent } from './pages/backoffice/clienti-backoffice/elimina-cliente-backoffice/elimina-cliente-backoffice.component';
import { ModificaClienteBackofficeComponent } from './pages/backoffice/clienti-backoffice/modifica-cliente-backoffice/modifica-cliente-backoffice.component';
import { EliminaLiveBackofficeComponent } from './pages/backoffice/live-backoffice/elimina-live-backoffice/elimina-live-backoffice.component';
import { AggiungiLiveBackofficeComponent } from './pages/backoffice/live-backoffice/aggiungi-live-backoffice/aggiungi-live-backoffice.component';
import { ModificaLiveBackofficeComponent } from './pages/backoffice/live-backoffice/modifica-live-backoffice/modifica-live-backoffice.component';
import { ModificaOndemandBackofficeComponent } from './pages/backoffice/ondemand-backoffice/modifica-ondemand-backoffice/modifica-ondemand-backoffice.component';
import { EliminaOndemandBackofficeComponent } from './pages/backoffice/ondemand-backoffice/elimina-ondemand-backoffice/elimina-ondemand-backoffice.component';
import { AggiungiOndemandBackofficeComponent } from './pages/backoffice/ondemand-backoffice/aggiungi-ondemand-backoffice/aggiungi-ondemand-backoffice.component';
import { EliminaAbbonamentoBackofficeComponent } from './pages/backoffice/abbonamenti-backoffice/elimina-abbonamento-backoffice/elimina-abbonamento-backoffice.component';
import { NewsBackofficeComponent } from './pages/backoffice/news-backoffice/news-backoffice.component';
import { AggiungiNewsBackofficeComponent } from './pages/backoffice/news-backoffice/aggiungi-news-backoffice/aggiungi-news-backoffice.component';
import { EliminaNewsBackofficeComponent } from './pages/backoffice/news-backoffice/elimina-news-backoffice/elimina-news-backoffice.component';
import { ModificaNewsBackofficeComponent } from './pages/backoffice/news-backoffice/modifica-news-backoffice/modifica-news-backoffice.component';
import { ConfermaClientiBackofficeComponent } from './pages/backoffice/clienti-backoffice/conferma-clienti-backoffice/conferma-clienti-backoffice.component';
import { SospendiAbbonamentoBackofficeComponent } from './pages/backoffice/abbonamenti-backoffice/sospendi-abbonamento-backoffice/sospendi-abbonamento-backoffice.component';
import { AttivaAbbonamentoBackofficeComponent } from './pages/backoffice/abbonamenti-backoffice/attiva-abbonamento-backoffice/attiva-abbonamento-backoffice.component';
import { ModalAggiungiCanaleBackofficeComponent } from './pages/backoffice/canali-backoffice/modal-aggiungi-canale-backoffice/modal-aggiungi-canale-backoffice.component';
import { CategorieBackofficeComponent } from './pages/backoffice/categorie-backoffice/categorie-backoffice.component';
import { AggiungiCategoriaBackofficeComponent } from './pages/backoffice/categorie-backoffice/aggiungi-categoria-backoffice/aggiungi-categoria-backoffice.component';
import { ModificaCategoriaBackofficeComponent } from './pages/backoffice/categorie-backoffice/modifica-categoria-backoffice/modifica-categoria-backoffice.component';
import { EliminaCategoriaBackofficeComponent } from './pages/backoffice/categorie-backoffice/elimina-categoria-backoffice/elimina-categoria-backoffice.component';
import { ModalAggiungiCategoriaBackofficeComponent } from './pages/backoffice/categorie-backoffice/modal-aggiungi-categoria-backoffice/modal-aggiungi-categoria-backoffice.component';
import { EliminaImmagginiCanaleComponent } from './pages/backoffice/canali-backoffice/elimina-immaggini-canale/elimina-immaggini-canale.component';
import { EliminaImmagginiNewsComponent } from './pages/backoffice/news-backoffice/elimina-immaggini-news/elimina-immaggini-news.component';
import { ModificaPasswordClienteBackofficeComponent } from './pages/backoffice/clienti-backoffice/modifica-password-cliente-backoffice/modifica-password-cliente-backoffice.component';
import { AggiungiImmaginiNewsBackofficeComponent } from './pages/backoffice/news-backoffice/aggiungi-immagini-news-backoffice/aggiungi-immagini-news-backoffice.component';
import { RaccoltaComponent } from './common/raccolta/raccolta.component';
import { ImagePipe } from './shared/image.pipe';
// BACKOFFICE
// PAGES


registerLocaleData(it);
registerSwiperElement()

@NgModule({
  declarations: [
    AppComponent,
    NotiziaComponent,
    HeaderComponent,
    FooterComponent,
    PlayerComponent,
    PlayerEvidenzaComponent,
    VideoLoadingComponent,
    PlayerLiveComponent,
    ErrorComponent,
    CookiesComponent,
    VideoThumbnailComponent,
    GalleriaVideoComponent,
    GalleriaCanaliComponent,
    CanaleThumbnailComponent,
    NessunAbbonamentoComponent,
    SliderComponent,
    SearchTitoloCanalePipe,
    SearchDescrizioneCanalePipe,
    SearchTitoloCategoriaPipe,
    SearchNomeCognomeClientePipe,
    SearchEmailClientePipe,
    SearchClienteAbbonamentoPipe,
    SearchStatoAbbonamentoPipe,
    SearchTitoloLivePipe,
    SearchCanaleLivePipe,
    SearchTitoloNewsPipe,
    SearchDataNewsPipe,
    SearchUsernameUtentePipe,
    SearchTipoUtentePipe,
    SearchClienteTicketPipe,
    SearchStatoTicketPipe,
    SearchTitoloOndemandPipe,
    SearchCanaleOndemandPipe,
    HomeComponent,
    CondizioniComponent,    
    PrivacyComponent,
    ContattaciComponent,    
    LoginComponent,
    RegistrazioneComponent,
    ResetComponent,
    ResetPasswordComponent,
    LiveComponent,
    ConfermaComponent,
    CanaleComponent,    
    NewsComponent,   
    NotiziaComponent, 
    ProfiloComponent,    
    AbbonamentiProfiloComponent,
    GestisciProfiloComponent,
    TicketProfiloComponent,
    PagamentoProfiloComponent,
    AnnulloPagamentoComponent,    
    OnDemandComponent,    
    LoginBackofficeComponent,
    HomepageBackofficeComponent,
    BackofficeComponent,
    HeaderBackofficeComponent,
    CanaliBackofficeComponent,
    LiveBackofficeComponent,
    OndemandBackofficeComponent,
    ClientiBackofficeComponent,
    AbbonamentiBackofficeComponent,
    UtentiBackofficeComponent,
    TicketsBackofficeComponent,
    AggiungiUtenteBackofficeComponent,
    ModificaUtenteBackofficeComponent,
    EliminaUtenteBackofficeComponent,    
    AggiungiCanaleBackofficeComponent,
    EliminaCanaleBackofficeComponent,
    ModificaCanaleBackofficeComponent,
    ModificaTicketBackofficeComponent,
    EliminaTicketBackofficeComponent,
    AggiungiTicketBackofficeComponent,
    ChiudiTicketBackofficeComponent,
    AggiungiClienteBackofficeComponent,
    ModificaClienteBackofficeComponent,
    EliminaClienteBackofficeComponent,
    EliminaLiveBackofficeComponent,
    AggiungiLiveBackofficeComponent,
    ModificaLiveBackofficeComponent,
    ModificaOndemandBackofficeComponent,
    EliminaOndemandBackofficeComponent,
    AggiungiOndemandBackofficeComponent,
    EliminaAbbonamentoBackofficeComponent,    
    NewsBackofficeComponent,
    AggiungiNewsBackofficeComponent,
    EliminaNewsBackofficeComponent,
    ModificaNewsBackofficeComponent,            
    ConfermaClientiBackofficeComponent,    
    SospendiAbbonamentoBackofficeComponent,
    AttivaAbbonamentoBackofficeComponent,    
    ModalAggiungiCanaleBackofficeComponent,  
    CategorieBackofficeComponent,
    AggiungiCategoriaBackofficeComponent,
    ModificaCategoriaBackofficeComponent,
    EliminaCategoriaBackofficeComponent,
    ModalAggiungiCategoriaBackofficeComponent,    
    EliminaImmagginiCanaleComponent,
    EliminaImmagginiNewsComponent,
    ModificaPasswordClienteBackofficeComponent,
    DownloadComponent,
    AggiungiImmaginiNewsBackofficeComponent,
    SingolaNewsComponent,
    RaccoltaComponent,
    ImagePipe,    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,    
    NzAlertModule,
    NzButtonModule,
    NzCardModule,
    NzCheckboxModule,
    NzDatePickerModule,
    NzDividerModule,
    NzDropDownModule,
    NzEmptyModule,
    NzFormModule,
    NzIconModule,
    NzInputModule,
    NzInputNumberModule,
    NzMenuModule,
    NzModalModule,
    NzPopconfirmModule,
    NzProgressModule,
    NzSelectModule,
    NzTableModule,
    NzTabsModule,
    NzTimePickerModule,
    NzToolTipModule,
    NzUploadModule,
    NzSpinModule,        
    NzImageModule,
    UppyAngularDashboardModule,
    RecaptchaV3Module
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true },
    { provide: NZ_I18N, useValue: it_IT },
    { provide: NZ_DATE_LOCALE, useValue: IT },
    { provide: LOCALE_ID, useValue: "it" },
    { provide: DatePipe },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha },    
    provideAnimationsAsync(),
    provideHttpClient()
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
