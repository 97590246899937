<!-- CONTROLLI -->
<div (click)="onPrev()" *ngIf="index != slides" [@fadeInOut] class="controlli cursor-pointer" id="indietro">
    <svg viewBox="0 0 32 32" fill="none" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="h-12 transition-all duration-300">
        <path d="M14.8206 15.9049C14.7685 15.9569 14.7685 16.0413 14.8206 16.0934L21.1381 22.4109C21.3984 22.6712 21.3984 23.0934 21.1381 23.3537L19.5353 24.9565C19.275 25.2168 18.8529 25.2168 18.5925 24.9565L11.7386 18.1026C11.7283 18.0935 11.7183 18.084 11.7084 18.0742L10.1057 16.4714C9.84531 16.2111 9.84531 15.789 10.1057 15.5286L18.5909 7.04335C18.8513 6.783 19.2734 6.783 19.5338 7.04335L21.1365 8.64612C21.3969 8.90647 21.3969 9.32858 21.1365 9.58893L14.8206 15.9049Z" fill="currentColor" />
    </svg>
</div>
<div (click)="onNext()" *ngIf="index < sliderLength" [@fadeInOut] class="controlli cursor-pointer" id="avanti">
    <svg viewBox="0 0 32 32" fill="none" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" class="h-12 transition-all duration-300">
        <path d="M17.947 16.095C17.999 16.043 17.999 15.9585 17.947 15.9065L11.6295 9.58899C11.3691 9.32864 11.3691 8.90653 11.6295 8.64618L13.2323 7.04341C13.4926 6.78306 13.9147 6.78306 14.1751 7.04341L21.0289 13.8973C21.0392 13.9064 21.0493 13.9158 21.0591 13.9257L22.6619 15.5285C22.9223 15.7888 22.9223 16.2109 22.6619 16.4713L14.1766 24.9565C13.9163 25.2169 13.4942 25.2169 13.2338 24.9565L11.631 23.3538C11.3707 23.0934 11.3707 22.6713 11.631 22.411L17.947 16.095Z" fill="currentColor" />
    </svg>
</div>    
<!-- CONTROLLI -->

<!-- SLIDER -->
<swiper-container #swiper [loop]="loop" [observer]="true" [observeParents]="true" [direction]="direction" [navigation]="{prevEl: '#indietro', nextEl: '#avanti'}" [navigation]="navigation" [slidesPerView]="slidesPerView" [grabCursor]="grabCursor" [spaceBetween]="spaceBetween" [breakpoints]="breakpoints">    
    <ng-content></ng-content>
</swiper-container>
<!-- SLIDER -->