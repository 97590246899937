<div class="flex lg:flex-row flex-col w-full">
    <div class="lg:w-2/3 w-full">
        <media-player #video oncontextmenu="return false;" class="live-player">
            <media-provider>
                <media-poster class="live-player-poster" [src]="currentVideo?.poster"></media-poster>
            </media-provider>
            <media-video-layout></media-video-layout>
        </media-player>
    </div>
    <div class="lg:w-1/3 w-full lista" *ngIf="!carica" [@fadeInOut]>        
        <ng-container *ngFor="let live of lives;">                
            <div class="item-list" [ngStyle]="{'background-image': 'url(\'' + (live.copertina_canale | image) + '\')'}" (click)="onVideoChange(live)" [ngClass]="{ 'selected': live.selected }">
                <div class="lg:w-1/3 w-full my-auto">
                    <img #icona [src]="live.icona | image" (error)="icona.src = '../../../assets/images/Logo bianco.png'" class="watermark grayscale-0" alt="Icona">
                </div>
                <div class="lg:w-2/3 w-full my-auto" [attr.title]="live.canale">
                    <p class="font-semibold text-light mb-0 px-3 testo" style="word-break: break-all;">{{ live.canale }}</p>
                </div>
            </div>
        </ng-container>           
        <ng-container *ngIf="lives.length <= 0" [@fadeInOut]>Nessun live presente</ng-container> 
    </div>
</div>

<div class="mobile-lives-list" *ngIf="lives.length > 0">
    <div class="playerlist-icon" *ngFor="let live of lives" (click)="onVideoChange(live)" [ngClass]="{'selected-mobile': live.selected}">
        <img #icona_mobile [src]="live.icona ? url + 'immagine/' + live.icona : '../../../assets/images/Logo bianco.png'" (error)="icona_mobile.src = '../../../assets/images/Logo bianco.png'" class="watermark" alt="Icona">
    </div>
</div>