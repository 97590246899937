<!-- ONDEMANDS -->
<h3 class="relative z-10 mb-4 mt-16 font-bold text-2xl text-white lg:text-3xl testo" *ngIf="currVideo">Altro da {{nome}}</h3>
<h3 class="relative z-10 mt-16 font-bold text-2xl text-white lg:text-3xl testo" *ngIf="!currVideo">{{nome}}</h3>
<p class="relative z-10 mb-4 font-normal text-white text-md testo" *ngIf="!currVideo">{{desc}}</p>

<div class="relative" *ngIf="ondemands.length > 0">
    <!-- SLIDER -->
    <app-slider [navigation]="false" [loop]="false" [centeredSlides]="false" [grabCursor]="true" [breakpoints]="{'0.00': {slidesPerView: 1, spaceBetween: 10}, '@0.75': {slidesPerView: 2, spaceBetween: 10}, '@1.00': { slidesPerView: 3, spaceBetween: 10 }, '@1.50': { slidesPerView: 5, spaceBetween: 10 }}" [sliderLength]="ondemands.length">
        <swiper-slide [lazy]="false" *ngFor="let ondemand of ondemands;" class="h-auto">
            <app-video-thumbnail (click)="onOndemand(ondemand.id)" [ondemand]="ondemand"></app-video-thumbnail>
        </swiper-slide>
    </app-slider>
    <!-- SLIDER -->

</div>
<!-- ONDEMANDS -->