<media-player oncontextmenu="return false;" #video class="player">
  
  <media-provider></media-provider>
  <media-video-layout></media-video-layout>

  <!-- CUSTOM CONTROLS -->
  <media-controls class="vds-controls">
    <media-controls-group class="vds-controls-group flex w-full justify-between px-1.5 py-1">

      <media-tooltip class="vds-arrow-left-tooltip vds-tooltip" style="display: contents;" data-media-play-tooltip="">

        <media-tooltip-trigger style="display: contents;">
          
          <media-toggle-button class="vds-arrow-left-button vds-button" aria-label="Return" data-media-tooltip="return" tabindex="0" role="button" type="button" aria-pressed="false" data-describedby="media-tooltip-1" (click)="onExit()">
            <slot name="back-icon" data-class="vds-icon vds-arrow-left-icon" style="display: contents;">
              <svg fill="none" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" class="vds-icon vds-arrow-left-icon" viewBox="0 0 32 32" data-icon="back">
                <path d="M13.0908 14.3334C12.972 14.3334 12.9125 14.1898 12.9965 14.1058L17.7021 9.40022C17.9625 9.13987 17.9625 8.71776 17.7021 8.45741L16.2879 7.04319C16.0275 6.78284 15.6054 6.78284 15.3451 7.04319L6.8598 15.5285C6.59945 15.7888 6.59945 16.2109 6.8598 16.4713L8.27401 17.8855L8.27536 17.8868L15.3453 24.9568C15.6057 25.2172 16.0278 25.2172 16.2881 24.9568L17.7024 23.5426C17.9627 23.2822 17.9627 22.8601 17.7024 22.5998L12.9969 17.8944C12.9129 17.8104 12.9724 17.6668 13.0912 17.6668L26 17.6668C26.3682 17.6668 26.6667 17.3683 26.6667 17.0001V15.0001C26.6667 14.6319 26.3682 14.3334 26 14.3334L13.0908 14.3334Z" fill="currentColor" />
              </svg>
            </slot>            
          </media-toggle-button>  

        </media-tooltip-trigger>

        <media-tooltip-content class="vds-tooltip-content" placement="top start" id="media-tooltip-1" role="tooltip" data-placement="top start" style="position: absolute; top: calc(-18.5px + calc(-1 * var(--media-tooltip-y-offset, 0px))); left: calc(5px + var(--media-tooltip-x-offset, 0px)); width: max-content; display: none;">
          <span class="vds-arrow-left-tooltip-text">Return</span>          
        </media-tooltip-content>

      </media-tooltip>

      <media-tooltip>

        <media-tooltip-trigger style="display: contents;">

          <media-toggle-button class="vds-x-mark-button vds-button" aria-label="Exit" data-media-tooltip="exit" tabindex="0" role="button" type="button" aria-pressed="false" data-describedby="media-tooltip-2" (click)="onExit()">
            <slot name="exit-icon" data-class="vds-icon vds-x-mark-icon" style="display: contents;">
              <svg fill="none" aria-hidden="true" focusable="false" xmlns="http://www.w3.org/2000/svg" class="vds-icon vds-x-mark-icon" viewBox="0 0 32 32" data-icon="exit">
                <path d="M10.2017 23.401C10.462 23.6613 10.8841 23.6613 11.1445 23.401L15.9057 18.6398C15.9577 18.5877 16.0422 18.5877 16.0942 18.6398L20.8555 23.401C21.1158 23.6613 21.5379 23.6613 21.7983 23.401L23.401 21.7982C23.6614 21.5378 23.6614 21.1157 23.401 20.8554L18.6398 16.0942C18.5878 16.0421 18.5878 15.9577 18.6398 15.9056L23.401 11.1444C23.6613 10.8841 23.6613 10.462 23.401 10.2016L21.7982 8.59886C21.5379 8.33851 21.1158 8.33851 20.8554 8.59886L16.0942 13.36C16.0422 13.4121 15.9577 13.4121 15.9057 13.36L11.1445 8.59887C10.8842 8.33852 10.4621 8.33852 10.2017 8.59887L8.59894 10.2016C8.33859 10.462 8.33859 10.8841 8.59894 11.1445L13.3601 15.9056C13.4122 15.9577 13.4122 16.0421 13.3601 16.0942L8.59889 20.8554C8.33854 21.1157 8.33854 21.5378 8.59889 21.7982L10.2017 23.401Z" fill="currentColor" />
              </svg>
            </slot>
          </media-toggle-button>

        </media-tooltip-trigger>

        <media-tooltip-content class="vds-tooltip-content" placement="top start" id="media-tooltip-2" role="tooltip" data-placement="top start" style="position: absolute; top: calc(-18.5px + calc(-1 * var(--media-tooltip-y-offset, 0px))); left: calc(5px + var(--media-tooltip-x-offset, 0px)); width: max-content; display: none;">
          <span class="vds-x-mark-tooltip-text">Exit</span>          
        </media-tooltip-content>

      </media-tooltip>
      
    </media-controls-group>        
  </media-controls>
  <!-- CUSTOM CONTROLS -->

</media-player>