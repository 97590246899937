<div id="wrapper">
  <ul nz-menu *ngIf="!collapse" nzMode="inline" class="border border-t-0" id="menu">
    <div id="logo" class="my-4 mx-auto"></div>
    <li nz-menu-item  routerLink="/backoffice/home" routerLinkActive="active">      
      <span nz-icon nzType="home" nzTheme="outline"></span>
      <span>Home</span>
    </li>
    <li nz-submenu routerLinkActive="nzOpen" #rla1="routerLinkActive" [nzOpen]="rla1.isActive" nzTitle="Gestione Video" nzIcon="video-camera">
      <ul>
        <li nz-menu-item routerLinkActive="active" [routerLink]="user?.tipo == 'Amministratore' ? '/backoffice/canali' : '/backoffice/canali/' + user?.id_canale">{{ user?.tipo == 'Amministratore' ? 'Canali' : 'Canale' }}</li>   
        <li nz-menu-item routerLinkActive="active" routerLink="/backoffice/categorie" nzMatchRouter *ngIf="user?.tipo == 'Amministratore'">Categorie</li>   
        <li nz-menu-item routerLinkActive="active" routerLink="/backoffice/ondemand" nzMatchRouter>On Demand</li>
        <li nz-menu-item routerLinkActive="active" routerLink="/backoffice/live" nzMatchRouter>Live</li>
      </ul>
    </li>
    <li nz-submenu routerLinkActive="nzOpen" #rla2="routerLinkActive" [nzOpen]="rla2.isActive" nzTitle="Gestione Clienti" nzIcon="user" *ngIf="user?.tipo == 'Amministratore'">
      <ul>
        <li nz-menu-item routerLinkActive="active" routerLink="/backoffice/clienti" nzMatchRouter>Clienti</li>
        <li nz-menu-item routerLinkActive="active" routerLink="/backoffice/abbonamenti" nzMatchRouter>Abbonamenti</li>
        <li nz-menu-item routerLinkActive="active" routerLink="/backoffice/tickets" nzMatchRouter>Tickets</li>
      </ul>
    </li>     
    <li nz-menu-item routerLink="/backoffice/news" routerLinkActive="active" *ngIf="user?.tipo == 'Amministratore'">
      <span nz-icon nzType="notification" nzTheme="outline"></span>
      <span>News</span>
    </li>
    <li nz-menu-item routerLink="/backoffice/utenti" routerLinkActive="active" *ngIf="user?.tipo == 'Amministratore'">
      <span nz-icon nzType="user" nzTheme="outline"></span>
      <span>Utenti</span>
    </li>
    <a nz-menu-item target="_blank" [href]="url" nz-menu-item class="text-decoration-none link-menu">      
      <span nz-icon nzType="shop" nzTheme="outline"></span>
      <span>Vai alla Web TV</span>      
    </a>
    <li nz-menu-item nz-popconfirm nzPopconfirmTitle="Sei sicuro di voler effettuare il logout?." (nzOnConfirm)="onLogout()" [nzOkText]="'Esci'">      
      <span nz-icon nzType="logout" nzTheme="outline"></span>
      <span>Log-out</span>
    </li>
  </ul>
  <ng-container *ngIf="collapse">

    <div class="header-placeholder"></div>
    <div class="top-bar border-b border-[#303030] px-8">
      <i class="bi text-xl" [ngClass]="!open ? 'bi-list' : 'bi-x-lg'" type="button" (click)="open = !open"></i>
      <img src="../../../assets/images/Logo bianco.png" alt="Logo Seven Live Web TV">
    </div>    
    <nav>      
      <div class="links flex flex-col divide-y divide-[#303030]" [class.open]="open">

          <a routerLinkActive="active" (click)="open = false" routerLink="/backoffice/home" [routerLinkActiveOptions]="{exact: true}" class="nav-link"><i class="bi bi-house-door-fill"></i> Home</a>
          <a routerLinkActive="active" (click)="open = false" [routerLink]="user?.tipo == 'Amministratore' ? '/backoffice/canali' : '/backoffice/canali/' + user?.id_canale"><i class="bi bi-bookmark-fill"></i> {{ user?.tipo == 'Amministratore' ? 'Canali' : 'Canale' }}</a>
          <a routerLinkActive="active" (click)="open = false" routerLink="/backoffice/categorie" *ngIf="user?.tipo == 'Amministratore'"><i class="bi bi-bookmarks-fill"></i> Categorie</a>
          <a routerLinkActive="active" (click)="open = false" routerLink="/backoffice/ondemand"><i class="bi bi-camera-reels-fill"></i> On Demand</a>
          <a routerLinkActive="active" (click)="open = false" routerLink="/backoffice/live"><i class="bi bi-camera-video-fill"></i> Live</a>          
          <a routerLinkActive="active" (click)="open = false" routerLink="/backoffice/clienti" *ngIf="user?.tipo == 'Amministratore'"><i class="bi bi-people-fill"></i> Clienti</a>
          <a routerLinkActive="active" (click)="open = false" routerLink="/backoffice/abbonamenti" *ngIf="user?.tipo == 'Amministratore'"><i class="bi bi-credit-card-fill"></i> Abbonamenti</a>
          <a routerLinkActive="active" (click)="open = false" routerLink="/backoffice/tickets" *ngIf="user?.tipo == 'Amministratore'"><i class="bi bi-ticket-fill"></i> Tickets</a>
          <a routerLinkActive="active" (click)="open = false" routerLink="/backoffice/news" *ngIf="user?.tipo == 'Amministratore'"><i class="bi bi-newspaper"></i> News</a>
          <a routerLinkActive="active" (click)="open = false" routerLink="/backoffice/utenti" *ngIf="user?.tipo == 'Amministratore'"><i class="bi bi-person-fill"></i> Utenti</a>
          <a (click)="onLogout()"><i class="bi bi-box-arrow-left"></i> LogOut</a>
      </div>
  </nav>
  </ng-container>
  <div id="contenuto" class="lg:px-20 p-8 lg:py-16 my-auto content w-full">
    <router-outlet></router-outlet>
  </div>
</div>