import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { style, animate, transition, trigger } from '@angular/animations';
import { ApiService } from '../../services/api.service';
import { Live } from '../../shared/interfacce.model';
import { MediaPlayerElement } from 'vidstack/elements';

@Component({
  selector: 'app-player-evidenza',
  templateUrl: './player-evidenza.component.html',
  styleUrls: ['./player-evidenza.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate(500, style({ opacity: 0 }))
      ])
    ])
  ]
})
export class PlayerEvidenzaComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  @ViewChild('video') video?: ElementRef<MediaPlayerElement>

  lives: Live[] = [];

  currentvideo: { src: string, poster: string, titolo: string, link: string } = { src: '', poster: '', titolo: '', link: '' };

  abbonamento: boolean = false;
  loading: boolean = true;

  muted: boolean = true;

  ngOnInit(): void {

    // Recupero le live del giorno
    this.apiService.getLives().subscribe({
      next: (data) => this.lives = [...data],
      error: (err) => this.apiService.error("<p><strong>I video live non sono stati caricati correttamente! </strong></p> <p>Ricaricare la pagina oppure aspettare qualche secondo. </p><p>In caso di errore continuo contattaci a <strong><a href='mailto:webtv@7live.tv'>webtv@7live.tv</a></strong>!</p>"),
      complete: () => {
        this.setLive(this.lives[Math.floor(Math.random() * this.lives.length)])
      }
    })
  }

  ngAfterViewInit(): void {
    this.muted = this.video?.nativeElement.muted || true;

    if (this.video) {

      this.video.nativeElement.logLevel = 'warn';
      this.video.nativeElement.loop = true;
      this.video.nativeElement.streamType = 'live';
      this.video.nativeElement.viewType = 'video';
      this.video.nativeElement.crossOrigin = true;
      this.video.nativeElement.playsInline = true;
      this.video.nativeElement.preload = 'auto';
      this.video.nativeElement.liveEdgeTolerance = 10;
      this.video.nativeElement.autoPlay = true;
      this.video.nativeElement.clipStartTime = 1;
      this.video.nativeElement.clipEndTime = 30;

      this.video.nativeElement.addEventListener('replay', () => {
        this.video!.nativeElement.currentTime = 0;
      })

    }
  }

  onMute(muted: boolean) {
    this.video!.nativeElement.muted = !muted;
    this.muted = !muted
  }

  setLive(live: Live) {

    if (this.video) {

      this.video.nativeElement.src = live.cloudflare_video_url;
      this.video.nativeElement.title = live.titolo;
      this.video.nativeElement.storage = '7live-' + live.titolo.replace(/ /g, '-')
    }

    this.currentvideo = { src: live.cloudflare_video_url, poster: live.cloudflare_image_url, titolo: live.titolo, link: '/ondemand/' + btoa("id_ondemand=" + live.id_ondemand) }

  }

}