import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-aggiungi-categoria-backoffice',
  templateUrl: './aggiungi-categoria-backoffice.component.html',
  styleUrls: ['./aggiungi-categoria-backoffice.component.css']
})
export class AggiungiCategoriaBackofficeComponent implements OnInit {

  constructor(private apiService: ApiService, private router: Router) { }

  formCategoria!: FormGroup;

  loading: boolean = false;

  alertMessage: string = '';
  alertType: "success" | "info" | "warning" | "error" = 'success';
  alertVisible: boolean = false;

  ngOnInit(): void {

    // Creo il form con i suoi controlli
    this.formCategoria = new FormGroup({
      titolo: new FormControl(null),
      descrizione: new FormControl(null)
    })
  }

  onAnnulla() {
    this.router.navigate(['/backoffice/categorie']);
  }

  onError(message: string) {
    window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    this.alertMessage = message;
    this.alertVisible = true;
    this.alertType = 'error';
    this.loading = false;
  }

  onSubmit() {

    // Formo l'oggetto per la richiesta dell'api
    let categoria = {
      titolo: this.formCategoria.get('titolo')?.value,
      descrizione: this.formCategoria.get('descrizione')?.value,
    }

    this.loading = true;

    // Aggiungo la categoria al db
    this.apiService.addCategoria(categoria).subscribe({
      next: (data) => this.apiService.setCategorie(),
      error: (err) => this.onError(err.error.message),
      complete: () => {
        this.loading = false;
        this.router.navigate(['/backoffice/categorie']);
      }
    })
  }

}