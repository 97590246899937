import { Component, inject, OnInit } from '@angular/core';
import { NZ_MODAL_DATA, NzModalRef } from 'ng-zorro-antd/modal';
import { ApiService } from '../../../../services/api.service';

@Component({
  selector: 'app-elimina-immaggini-news',
  templateUrl: './elimina-immaggini-news.component.html',
  styleUrls: ['./elimina-immaggini-news.component.css']
})
export class EliminaImmagginiNewsComponent implements OnInit {

  constructor(private apiService: ApiService) { }

  loading: boolean = false;
  
  readonly #modal = inject(NzModalRef);
  readonly nzModalData = inject(NZ_MODAL_DATA);  

  copertina: boolean = false;
  tipo: string = 'copertina';
  categoria: string = 'news';

  ngOnInit(): void {
    
    this.copertina = this.nzModalData.copertina;
    this.tipo = this.nzModalData.copertina ? 'copertina' : 'immagine';
    this.categoria = this.nzModalData.copertina ? 'news' : 'immagini_news';

  }

  onElimina() {    

    this.loading = true;

    // Elimino la copertina della news
    this.apiService.deleteFile(this.tipo, this.categoria, this.nzModalData.nome_immagine!, '', this.nzModalData.id_immagine!).subscribe({
      next: (data) => {
        if (this.copertina)
          this.apiService.setNews();
        else
          this.apiService.setImmaginiNews(this.nzModalData.id_news);

      },
      error: (err) => {
        this.loading = false
        this.apiService.error(err.error.message)
      },
      complete: () => {
        this.loading = false;
        this.#modal.destroy(true);
      }
    })

  }

  onClose() {
    this.#modal.destroy(false);
  }

}