<div class="px-8 xl:px-container lg:px-16">

  <!-- LOGO MOBILE -->
  <div class="flex justify-center py-8 lg:py-4">
    <img class="relative z-10 lg:hidden" src="../../../assets/images/Logo bianco.png" height="40px" alt="Logo 7live">
  </div>
  <!-- LOGO MOBILE -->

  <!-- BANNER -->  
  <div class="banner" [style.background-image]="" [ngStyle]="{'background-image': canale?.copertina ? 'url(' + (canale?.copertina | image) + ')': 'url(../../../assets/images/7L_tiling_bg_inv.png)', 'background-size': !canale?.copertina ? '20%' : 'cover'}" [ngClass]="{'bg-repeat bg-header-color bg-blend-multiply': !canale?.copertina}" [@fadeInOut]>
    <div class="sfumatura"></div>
  </div>
  <!-- BANNER -->

  <!-- ONDEMANDS -->
  <div class="h-full-nav ondemands" *ngIf="ondemands.length > 0" [@fadeInOut]>    

    <h3 class="relative z-10 mt-8 font-bold text-3xl text-white lg:text-4xl testo">{{canale?.nome}}</h3>
    <!-- <p class="relative z-10 mt-4 font-semibold text-white text-xl testo">{{canale?.descrizione}}</p>   -->

    <!-- NO VIDEO -->  
    <h3 class="d-flex justify-content-center align-items-center mt-3" *ngIf="ondemands.length <= 0 && !loading" [@fadeInOut]>Questo canale non ha video</h3>  
    <!-- NO VIDEO -->

    <!-- RACCOLTA -->    
    <!-- <app-raccolta [ondemands]="ondemands.slice(1,10)" class="my-8"></app-raccolta> -->
    <!-- RACCIOLTA -->

    <!-- TUTTI -->        
    <div class="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4 gap-y-8 my-8">
      <div *ngFor="let ondemand of ondemands.slice(0,ondemandLimit);">
        <app-video-thumbnail [ondemand]="ondemand" (click)="onOndemand(ondemand.id)"></app-video-thumbnail>
      </div>
      <ng-container *ngIf="ondemandLimit < ondemands.length;">
        <div class="aspect-[16/9] object-cover flex justify-center overflow-hidden cursor-pointer transition-all duration-200 flex-col basis-[32%] animate-pulse bg-gray-500" *ngFor="let number of [1,2,3,4,5]"></div>
      </ng-container>
    </div>
    <!-- TUTTI -->

  </div>
  <!-- ONDEMANDS -->
</div>