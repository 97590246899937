import { Component, Input } from '@angular/core';
import { Canale } from '../../shared/interfacce.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-galleria-canali',
  templateUrl: './galleria-canali.component.html',
  styleUrls: ['./galleria-canali.component.css']
})
export class GalleriaCanaliComponent {
  
  @Input() canali: Canale[] = [];
  @Input() evidenza: boolean = false;

  constructor(private router: Router) {}

  onCanale(canale: Canale): void {
    this.router.navigate(['/canali/' + canale.nome.replace(/ /g, '-').toLocaleLowerCase()])
  }

}
